import { SearchSource } from '@/ts/types/general.types'

type Props<T> = {
  /**
   * Query to search
   */
  results?: any
  /**
   * Sources where to search
   */
  sources?: SearchSource[]
}

const Results = ({ results, sources }: Props<T>): JSX.Element => {
  return results?.length > 0 ? (
    <div className="divide-y divide-gray-200">
      {results.map((result) => {
        const source = sources.find((source) => source.key === result._Type)
        return source ? <div>{source.renderItem(result)}</div> : null
      })}
    </div>
  ) : null
}

export default Results

/*        <Plural
          value={results?.length || 0}
          one="# resultado"
          other="# resultados"
        />*/
