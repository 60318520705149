import useSWR from 'swr'

import { findReadNotificationsCountByRecipientUUID } from '@/services/notificationService'

type ReturnProps = {
  count: number
}

type Props = {
  userUUID?: string
}

const useUnreadNotificationsCount = ({ userUUID }: Props): ReturnProps => {
  const { data: unreadNotifications } = useSWR(
    userUUID ? [userUUID, '/notifications/?Read=false&offset=0&limit=0&RecipientUUID'] : null,
    findReadNotificationsCountByRecipientUUID,
    { refreshInterval: 10000 }
  )

  return { count: unreadNotifications?.Count || 0 }
}

export default useUnreadNotificationsCount
