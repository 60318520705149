import CustomText from '@/components/base/CustomText'

import Avatar from '../../Avatar'

export const ConnectedItem = ({ connectedFollower, onClick }) => {
  return (
    <>
      <article
        onClick={onClick}
        className="flex px-4 pb-3 pt-4 gap-2 items-center w-full hover:bg-gray-200 cursor-pointer"
      >
        <div className="relative">
          <div className="h-2 w-2 bg-primary rounded-full bottom-0 absolute z-10 right-0 ring-2 ring-background" />
          <Avatar
            className="h-6 w-6 !rounded-full"
            borderWidth="sm"
            levelColor={connectedFollower?.LevelColor}
            image={connectedFollower?.Photo?.Thumbnail}
          />
        </div>
        <CustomText>{connectedFollower?.Name}</CustomText>
      </article>
    </>
  )
}
