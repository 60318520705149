import Image from 'next/image'
import { BsImageFill } from 'react-icons/bs'
import { FiChevronRight } from 'react-icons/fi'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import { getCountryFromName } from '@/utils/countries'

import CustomLink from '../../CustomLink'
import { useSearchbar } from '../../Layout/RightBar/SearchBar'

type Props = {
  /**
   * company to show
   */
  company?: any
}

const SearchResultItemCompany = ({ company }: Props): JSX.Element => {
  const { close } = useSearchbar()

  return (
    <CustomLink href={`/profile/c/${company.UUID}`} onClick={close} className="w-full block">
      <div className="w-full bg-white hover:bg-gray-100 p-3 overflow-hidden-z">
        <div className="flex items-center w-full space-x-3 box-border">
          <div>
            {company.Logo ? (
              <div className="rounded-full  relative overflow-hidden-z w-12 sm:w-16 h-12 sm:h-16 bg-gray-200">
                <Image src={company.Logo?.Thumbnail} fill alt={'company-logo'} className="object-cover rounded-full" />
              </div>
            ) : (
              <div className="flex items-center justify-center w-12 sm:w-16 h-12 sm:h-16 rounded-full bg-gray-200">
                <BsImageFill className="text-3xl opacity-75 text-gray-400" />
              </div>
            )}
          </div>
          <div className="flex-1 overflow-hidden-z space-y-1">
            <CustomText
              weight={FontWeight.SemiBold}
              size={TextSize.Tiny}
              className="block  text-brand group-hover:text-white leading-none uppercase m-0"
            >
              {company.Type === 'company' ? <span>Empresa</span> : null}
              {company.Type === 'foundation' ? <span>Fundación o ONG</span> : null}
              {company.Type === 'government' ? <span>Gobierno</span> : null}
            </CustomText>
            <h4 className="flex items-center justify-center text-gray-800 font-semibold group-hover:text-white leading-none text-sm sm:text-base truncate">
              {company.Name}

              <Image
                src={company.Logo?.Thumbnail}
                height={14}
                width={14}
                alt="/static/images/certified.svg"
                className="inline-block ml-2"
              />
            </h4>
            <CustomText
              size={TextSize.Caption}
              className="block font-medium leading-tight text-gray-700 group-hover:text-white truncate"
            >
              {getCountryFromName(company.Country)?.name || `Sin país`}
            </CustomText>
          </div>
          <div>
            <FiChevronRight />
          </div>
        </div>
      </div>
    </CustomLink>
  )
}

export default SearchResultItemCompany
