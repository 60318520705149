import Image from 'next/image'
import Link from 'next/link'
import { FaChevronRight } from 'react-icons/fa'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import useRequest2 from '@/hooks/useRequest2'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import useUserChallengeCount from '@/hooks/useUserChallengeCount'
import Challenge from '@/public/static/images/ranking/challenges.svg'
import Initiative from '@/public/static/images/ranking/initiatives.svg'
import Medals from '@/public/static/images/ranking/medals.svg'
import TokenFront from '@/public/static/images/token-front.png'
import { countDonationsByAuthorUUID } from '@/services/initiativesService'
import axios from '@/utils/axios'

const impactData = {
  challenge: { label: 'Desafíos', image: Challenge },
  medals: { label: 'Medallas', image: Medals },
  donations: { label: 'Iniciativas', image: Initiative },
  tokens: { label: 'Tokens', image: TokenFront },
}

export const ImpactSummarySection = (): JSX.Element => {
  const { author } = useSunetAuthors()
  const userUUID = author?.UUID
  const challengeCount = useUserChallengeCount({
    authorUUID: userUUID,
  })
  const path = author?.Type !== 'user' ? 'c' : 'u'

  const [donationsData] = useRequest2({
    requestKey: '/initiatives/donations/count',
    params: [userUUID],
    request: countDonationsByAuthorUUID,
  })

  const [badgesData] = useRequest2({
    requestKey: '/gamification/user-badges/count?AuthorUUID',
    params: [userUUID],
    request: (authorUUID) => axios.get(`/gamification/user-badges?AuthorUUID=${authorUUID}&limit=0&offset=0`),
  })

  const [tokenData] = useRequest2({
    requestKey: '/gamification/ranking?AuthorUUID',
    params: [userUUID],
    request: (userUUID) => axios.get(`/gamification/ranking?AuthorUUID=${userUUID}&Type=token`),
  })

  const tokenCount = tokenData?.data?.Data?.[0]?.TotalScore.toFixed(1)

  const badgeCount = badgesData?.data?.Data?.Count
  const donationCount = donationsData?.Count

  return (
    <section>
      <CustomText className="text-black mb-2" size={TextSize.SubTitle2} weight={FontWeight.SemiBold}>
        Resumen de impacto
      </CustomText>
      <div className="grid grid-cols-2 gap-2">
        <ImpactItem count={challengeCount || 0} type={'challenge'} href={`/profile/${path}/${userUUID}/challenges`} />
        <ImpactItem count={badgeCount || 0} type={'medals'} href={`/profile/${path}/${userUUID}/medals`} />
        <ImpactItem count={donationCount || 0} type={'donations'} href={`/profile/${path}/${userUUID}/initiatives`} />
        <ImpactItem count={tokenCount || 0} type={'tokens'} href={`/wallet`} />
      </div>
    </section>
  )
}

type ImpactItemProps = {
  type: string
  count: number
  href: string
}

const ImpactItem = ({ type, count, href }: ImpactItemProps): JSX.Element => {
  return (
    <Link
      href={href}
      className="bg-white sunet-shadow rounded-xl p-2 flex items-center gap-2 hover:bg-gray-4 cursor-pointer"
    >
      <Image alt={impactData[type].label} width={45} height={45} src={impactData[type].image} />
      <div>
        <CustomText className="text-black" weight={FontWeight.Bold} size={TextSize.Heading2}>
          {count}
        </CustomText>
        <div className="flex items-center gap-2">
          <CustomText className="text-black" weight={FontWeight.SemiBold} size={TextSize.Caption}>
            {impactData[type].label}
          </CustomText>
          <FaChevronRight className="fill-black" />
        </div>
      </div>
    </Link>
  )
}
