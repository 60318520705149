import { CountRanking } from '@/ts/types/gamification.types'

import axios, { getAxiosByAccessToken } from '../utils/axios'
import { startOfDay } from 'date-fns'

export const getRankingPodium = (params: unknown[]): Promise<CountRanking> => {
  const accessToken = params[0] as string
  const type = params[1]
  const selectedPeriod = params[2]

  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  const order = selectedPeriod === 'monthly' ? `&order=-MonthlyScore` : `&order=-YearlyScore`

  return axiosInstance
    .get(`/gamification/ranking?Type=${type}&offset=0&limit=3&include=Author${order}`)
    .then((res: { data: { Data: CountRanking } }) => res.data.Data)
}

export const findRankingsByPage = async (page: number, selectedPeriod: string, type: string): Promise<CountRanking> => {
  const order = selectedPeriod === 'monthly' ? `&order=-MonthlyScore` : `&order=-YearlyScore`
  return axios
    .get(`/gamification/ranking?offset=${page * 10}&limit=10&include=Author${order}&Type=${type}`)
    .then((res: { data: { Data: CountRanking } }) => res.data.Data)
}

export const findAllRewards = async (accessToken: string) => {
  const end = startOfDay(new Date()).toISOString()

  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  const res = await axiosInstance.get(`/gamification/rewards?include=Author&Enabled=true&ExpiresAt~ge=${end}`)
  return res.data.Data
}
export const filterRewards = async ({ maxScore, minScore, order, categoryUUID }) => {
  const end = startOfDay(new Date()).toISOString()

  const orderParam = order === 'asc' ? '&order=Score' : '&order=-Score'

  const maxScoreParam = maxScore > 0 ? `&Score~le=${maxScore}` : ''
  const minScoreParam = minScore > 0 ? `&Score~ge=${minScore}` : ''
  const categoryParam = categoryUUID !== '0' && categoryUUID ? `&CategoryUUID=${categoryUUID}` : ''

  const paramString = maxScoreParam + minScoreParam + orderParam + categoryParam
  const res = await axios.get(
    `/gamification/rewards?include=Author,WishList&ExpiresAt~ge=${end}&Enabled=true${paramString}`
  )
  return res.data.Data
}

export const redeemReward = async (rewardUUID: string) => {
  const res = await axios.post(`gamification/rewards/redeem`, { RewardUUID: rewardUUID })
  return res.data.Data
}

export const findUserRewardsByUserUUID = async (userUUID) => {
  const res = await axios.get(`/gamification/rewards/redeemed?AuthorUUID=${userUUID}&include=Reward,RewardWithCompany`)

  return res.data.Data
}

export const getRankingPosition = async (authorUUID, period, type) => {
  return await axios.post(`/gamification/ranking/position`, {
    AuthorUUID: authorUUID,
    Period: period,
    RankingType: type,
  })
}

export const getTokenSources = async (authorUUID: string) => {
  const res = await axios.post(`/gamification/metrics/tokens/top-five-actions`, { AuthorUUID: authorUUID })
  return res.data.Data
}

export const buyProduct = async (authorUUID: string, productUUID: string) => {
  const res = await axios.post(`/gamification/user-transactions/buy-product`, {
    AuthorUUID: authorUUID,
    ProductUUID: productUUID,
  })
  return res.data.Data
}

export const getCurrentLevelActions = async (authorUUID) => {
  const res = await axios.get(`/gamification/user-levels?AuthorUUID=${authorUUID}`)
  return res.data.Data[0]
}

export const getRewardsCategories = async () => {
  const res = await axios.get(`/gamification/rewards/categories`)
  return res.data.Data
}

export const getActivities = async (accessToken) => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios
  const res = await axiosInstance.get(
    `/gamification/challenges?Type=activity&include=CountActivities,CountActivitiesPoint`
  )

  return res.data.Data
}
export const getAuthorMedals = async (userUUID, accessToken) => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios
  const res = await axiosInstance.get(
    `/gamification/user-challenges?AuthorUUID=${userUUID}&Type=challenge&include=Challenge,Badge&order=-IsComplete`
  )

  return res.data.Data
}

export const wishReward = async (values) => {
  const res = await axios.post(`/gamification/rewards/wish-list`, values)
  return res
}
export const unwishReward = async (rewardUUID) => {
  const res = await axios.delete(`/gamification/rewards/wish-list/${rewardUUID}`)
  return res
}

export const getAuthorWishes = async (authorUUID) => {
  const res = await axios.get(
    `/gamification/rewards/wish-list?IsActive=true&AuthorUUID=${authorUUID}&include=Author,Reward,Company`
  )
  return res.data.Data
}

export const checkNewBadges = async () => {
  const res = await axios.get(`/gamification/badges/check-new`)
  return res.data.Data
}

export const markBadgeAsRead = async (values) => {
  const res = await axios.post(`gamification/user-challenges/read`, values)
  return res.data.Data
}

export const getRankingByAuthorUUID = async (authorUUID) => {
  const res = await axios.get(`/gamification/ranking?AuthorUUID=${authorUUID}&Type=token&include=Level`)
  return res.data.Data[0]
}

export const redeemAsGroup = async (values) => {
  const res = await axios.post(`gamification/rewards/group-redeem`, values)
  return res.data.Data
}
