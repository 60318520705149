import CustomText, { TextSize } from '@/components/base/CustomText'

type Props<T> = {
  /**
   * Query to search
   */
  results?: T[]
  /**
   * Sources where to search
   */
  query?: string
}

const Messages = <T,>({ results, query }: Props<T>): JSX.Element => {
  return !results || results.length === 0 ? (
    <div className="pt-2 px-2 sm:px-4 pb-2 sm:pb-4 min-h-20">
      {!results || !query ? (
        <div className="px-3 h-20 flex items-center justify-center w-full">
          <CustomText size={TextSize.Caption} className="m-0 leading-tight text-gray-600 text-center ">
            Escribe lo que deseas buscar para ver resultados.
          </CustomText>
        </div>
      ) : null}

      {results && results.length === 0 && query ? (
        <div className="px-3 h-20 flex items-center justify-center w-full">
          <CustomText size={TextSize.Caption} className="m-0 leading-tight text-gray-600 text-center ">
            No se encontraron resultados.
          </CustomText>
        </div>
      ) : null}
    </div>
  ) : null
}

export default Messages
