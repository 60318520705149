import { debounce } from 'lodash'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

import { SearchSource } from '@/ts/types/general.types'
import { sortByKey } from '@/utils/sort'

import useRequest from './useRequest'

type ReturnProps = {
  loading: boolean
  results: any
  currentSource: string
  setCurrentSource: Dispatch<SetStateAction<string>>
}

const useSearchQuery = ({ query, sources }: { query: string; sources: SearchSource[] }): ReturnProps => {
  // When null, search from sources.
  const [currentSource, setCurrentSource] = useState<string>(null)

  // Where the results will be stored after the request is made.
  const [results, setResults] = useState(null)

  // Execute actions from all source at once and then merge its results.
  const searchFromAllSources = async (query) => {
    const resultDict = {}
    sources.forEach(async (source) => {
      const actions = source.action
      const sourcePromises = actions.map((action) => {
        return action(query)
      })
      resultDict[`${source.key}`] = sourcePromises
    })

    const results = Object.keys(resultDict).map(async (resultKey) => {
      const resultItem = resultDict[resultKey]
      const sourceResult = await Promise.all(resultItem)
      const returnObj = {
        Items: [],
        count: 0,
      }
      sourceResult?.forEach((result) => {
        if (result?.Items?.length > 0) {
          result.Items = result.Items.map((item) => ({
            ...item,
            _Type: resultKey,
          }))
        }
      })
      returnObj.Items = sourceResult.reduce(
        (accumulator, current) => (current?.Items ? [...accumulator, ...current.Items] : accumulator),
        []
      )

      returnObj.count = sourceResult.reduce(
        (accumulator, current) => (current?.Items ? accumulator + current.Items.length : accumulator),
        0
      )

      return returnObj
    })

    const allResults = await Promise.all(results)

    const count = allResults.reduce(
      (accumulator, current) => (current?.Items ? accumulator + current.Items.length : accumulator),
      0
    )

    const items = allResults.reduce(
      (accumulator, current) => (current?.Items ? [...accumulator, ...current.Items] : accumulator),
      []
    )

    return {
      Count: count,
      Items: sortByKey(items, 'Similarity', true),
    }
  }

  const searchFromSingleSource = (source) => async (query) => {
    const actions = source.action

    const promises = actions.map((action) => {
      return action(query)
    })

    const results = await Promise.all(promises)
    console.log('results', results)

    results.forEach((result) => {
      if (result?.Items?.length > 0) {
        result.Items = result.Items.map((item) => ({
          ...item,
          _Type: source.key,
        }))
      }
    })

    const count = results.reduce(
      (accumulator, current) => (current?.Items ? accumulator + current.Items.length : accumulator),
      0
    )

    const items = results.reduce(
      (accumulator, current) => (current?.Items ? [...accumulator, ...current.Items] : accumulator),
      []
    )

    return {
      Count: count,
      Items: sortByKey(items, 'Similarity', true),
    }
  }

  // Depending on the current selected source, returns the corresponding request.
  const getRequest = () => (query, sourceKey) => {
    const source = sources.find((source) => source.key === sourceKey)
    if (source) {
      return searchFromSingleSource(source)(query)
    } else {
      return searchFromAllSources(query)
    }
  }

  const { exec, loading } = useRequest(getRequest())

  const handleSearch = useCallback(
    debounce(async (query, sourceKey) => {
      const result = await exec(query, sourceKey)
      setResults(result?.Items)
    }, 500),
    []
  )

  useEffect(() => {
    if (query) {
      handleSearch(query, currentSource)
    } else {
      setResults(null)
    }
  }, [query, currentSource])

  return { loading, results, currentSource, setCurrentSource }
}

export default useSearchQuery
