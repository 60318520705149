import { createContext, useContext, useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { FiSearch } from 'react-icons/fi'

import Card from '@/components/base/Card'
import Modal from '@/components/base/CustomModal'
import TextInput from '@/components/base/TextInput'

const SearchContext = createContext({ close: () => null, open: () => null })
export const useSearchbar = () => useContext(SearchContext)

const SearchBar = ({ children, showButton = true, customOpen, customSetOpen }): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')

  useEffect(() => {
    setOpen(customOpen)
  }, [customOpen])

  return (
    <SearchContext.Provider value={{ close: () => setOpen(false), open: () => setOpen(true) }}>
      {showButton ? (
        <button className="w-full" onClick={() => setOpen(true)}>
          <div className="cursor-pointer">
            <TextInput
              enableError={false}
              className="pointer-events-none"
              renderAccessory={() => <AiOutlineSearch className="text-primary" />}
              placeholder="Buscar en Sunet"
            />
          </div>
        </button>
      ) : null}

      <Modal
        animate
        open={open}
        onClose={() => {
          if (customSetOpen) {
            customSetOpen(false)
          } else {
            setOpen(false)
          }
        }}
        maxWidth={600}
        showCloseButton
        closeOnOutsideClick
      >
        <Card className="my-10 overflow-hidden-z !p-0">
          <div className="p-2  sm:p-4 bg-gray-200">
            <div className="relative rounded-lg overflow-hidden shadow-sunet transform translate-z-0">
              <div className="absolute top-0 bottom-0 left-0 flex items-center pointer-events-none">
                <FiSearch className="mx-3 text-brand" />
              </div>
              <input
                onChange={(e) => setQuery(e.target.value)}
                type="text"
                className="w-full pl-10 pr-5 py-3 focus:outline-none text-gray-800"
                placeholder={`Buscar...`}
              />
            </div>
          </div>

          <div className="max-h-lg overflow-y-auto relative " style={{ height: 600 }}>
            {children?.({ query, setQuery })}
          </div>
        </Card>
      </Modal>
    </SearchContext.Provider>
  )
}

export default SearchBar
