import {
  CftCategory,
  CftCreateEmission,
  CftEmission,
  CftItem,
  CftUpdateEmission,
  FootprintCalculatorResponse,
  GoalType,
} from '@/ts/types/footprint.types'
import { CountDef } from '@/ts/types/general.types'

import axios, { getAxiosByAccessToken } from '../utils/axios'

export const findAllGoalsByAuthorUUID = async (authorUUID: string, accessToken?: string): Promise<GoalType[]> => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance
    .get(`/footprints/goals?AuthorUUID=${authorUUID}&order=-CreatedAt`)
    .then((res: { data: { Data: GoalType[] } }) => res.data.Data)
}

export const findAllGoalsByAuthorUUIDAndPage = async (
  authorUUID: string,
  page: number
): Promise<CountDef<GoalType>> => {
  return axios
    .get(`/footprints/goals?AuthorUUID=${authorUUID}&offset=${page * 5}&order=-CreatedAt`)
    .then((res: { data: { Data: CountDef<GoalType> } }) => res.data.Data)
}

export const findGoalByUUID = async (params: unknown[]): Promise<GoalType> => {
  const goalUUID = params[0]
  return axios.get(`/footprints/goals/${goalUUID}`).then((res: { data: { Data: GoalType } }) => res.data.Data)
}

export const createGoal = async (values: GoalType): Promise<GoalType> => {
  return axios.post('/footprints/goals', values)
}

export const updateGoalByUUID = async (goalUUID: string, value: GoalType): Promise<GoalType> => {
  return axios.put(`/footprints/goals/${goalUUID}`, value)
}

/* Categories */
export const findAllCategories = async (): Promise<CftCategory[]> => {
  return axios.get('/footprints/categories').then((res: { data: { Data: CftCategory[] } }) => res.data.Data)
}

export const findAllCategoriesByUserType = async (userType: string): Promise<CftCategory[]> => {
  return axios
    .get(`/footprints/categories?UserType=${userType}`)
    .then((res: { data: { Data: CftCategory[] } }) => res.data.Data)
}

export const findCategoryByUUID = async (categoryUUID: string): Promise<CftCategory> => {
  return axios
    .get(`/footprints/categories/${categoryUUID}`)
    .then((res: { data: { Data: CftCategory } }) => res.data.Data)
}

/* Items */
export const findAllItemsByCategoryUUID = async (categoryUUID: string): Promise<CftItem[]> => {
  return axios
    .get(`/footprints/items?CategoryUUID=${categoryUUID}`)
    .then((res: { data: { Data: CftItem[] } }) => res.data.Data)
}

export const findAllItemsByCategoryUUIDAndUserType = async (
  categoryUUID: string,
  userType: 'carbon' | 'hydro'
): Promise<CftItem[]> => {
  return axios
    .get(`/footprints/items?CategoryUUID=${categoryUUID}&UserType=${userType}`)
    .then((res: { data: { Data: CftItem[] } }) => res.data.Data)
}

export const findItemByUUID = async (itemUUID: string): Promise<CftItem> => {
  return axios.get(`/footprints/items/${itemUUID}`).then((res: { data: { Data: CftItem } }) => res.data.Data)
}

export const findEmissionSumByCategoryAndTypeAndUserType = async (
  goalUUID: string,
  type: string
): Promise<CftEmission[]> => {
  return await axios
    .get(`/footprints/emissions?GoalUUID=${goalUUID}&Type=${type}`)
    .then((res: { data: { Data: CftEmission[] } }) => res.data.Data)
}

export const createEmission = async (values: CftCreateEmission): Promise<CftEmission> => {
  return axios.post(`/footprints/emissions`, values).then((res: { data: { Data: CftEmission } }) => res.data.Data)
}

export const updateEmissionByUUID = async (
  emissionUUID: string,
  updateValues: CftUpdateEmission
): Promise<CftEmission> => {
  return axios
    .put(`/footprints/emissions/${emissionUUID}`, updateValues)
    .then((res: { data: { Data: CftEmission } }) => res.data.Data)
}

export const deleteEmissionByUUID = async (emissionUUID: string): Promise<boolean> => {
  return axios.delete(`/footprints/emissions/${emissionUUID}`)
}

export const findAllEmissionsWithCategoryAndItem = async (goalUUID: string): Promise<CftEmission[]> => {
  return axios
    .get(`/footprints/emissions?GoalUUID=${goalUUID}&include=Item,Category`)
    .then((res: { data: { Data: CftEmission[] } }) => res.data.Data)
}

export const findEmissionByUUIDWithCategoryAndItem = async (emissionUUID: string): Promise<CftEmission> => {
  return axios
    .get(`/footprints/emissions/${emissionUUID}?include=Item,Category`)
    .then((res: { data: { Data: CftEmission } }) => res.data.Data)
}

export const calculateFootprint = async (values): Promise<FootprintCalculatorResponse> => {
  const res = await axios.post(`/footprints/emissions/form`, values)
  return res.data.Data
}

export const getFootprintForm = async (authorUUID) => {
  const res = await axios.get(`/footprints/emissions/form/exists?AuthorUUID=${authorUUID}`)
  return res.data.Data
}
