import Link from 'next/link'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import LoadingWrapper from '@/components/base/LoadingWrapper'
import FootprintProgress from '@/components/shared/FootprintProgress'
import { useAuthStore } from '@/dataStores/auth'
import useCftGoal from '@/hooks/useCftGoal'
import { getPercentageOfGoal } from '@/utils/cftCategories'

const CftProgress = (): JSX.Element => {
  const [user] = useAuthStore((state) => [state.user])

  const { actualGoal, loading } = useCftGoal({
    AuthorUUID: user?.UUID,
  })

  const carbonProgressPercentage = getPercentageOfGoal(actualGoal, 'carbon')
  const waterProgressPercentage = getPercentageOfGoal(actualGoal, 'water')

  return (
    <div>
      <Link href="/cft/profile-picker">
        <div>
          <CustomText className="text-black mb-2" size={TextSize.SubTitle2} weight={FontWeight.SemiBold}>
            Progreso de tu meta
          </CustomText>
          <LoadingWrapper loading={loading}>
            <div className="flex items-center justify-evenly w-full">
              <FootprintProgress color="green" className="w-20" percentage={carbonProgressPercentage} label="CO2" />
              <FootprintProgress color="blue" className="w-20" percentage={waterProgressPercentage} label="H2O" />
            </div>
          </LoadingWrapper>
        </div>
      </Link>
    </div>
  )
}

export default CftProgress
