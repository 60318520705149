import clsx from 'clsx'
import React from 'react'

import CustomText, { TextSize } from './CustomText'

type Props = {
  label?: string
  className?: string
}

const Badge = ({ label, className }: Props): JSX.Element => {
  return (
    <div className={clsx(className, 'absolute -top-2 w-5 h-5 -right-2 bg-red rounded-full p-0.5 z-10')}>
      <CustomText className="text-white text-center" size={TextSize.Tiny}>
        {label}
      </CustomText>
    </div>
  )
}

export default Badge
