import LoadingWrapper from '@/components/base/LoadingWrapper'
import useSearchQuery from '@/hooks/useSearchQuery'
import { SearchSource } from '@/ts/types/general.types'

import Counter from './Counter'
import Messages from './Messages'
import Results from './Results'
import SourceSelector from './SourceSelector'

type Props = {
  /**
   * Query to search
   */
  query?: string
  /**
   * Sources where to search
   */
  sources?: SearchSource[]
}

const SearchResults = ({ query, sources }: Props): JSX.Element => {
  const { results, loading, currentSource, setCurrentSource } = useSearchQuery({
    query,
    sources,
  })
  console.log(results, 'RESULTS')
  return (
    <div className="relative">
      <LoadingWrapper loading={loading}>
        <div className="sticky top-0 bg-white z-30 border-b border-gray-200">
          <SourceSelector sources={sources} currentSource={currentSource} onSelect={setCurrentSource} />
          <Counter results={results} query={query} />
        </div>

        <Messages query={query} results={results} />
        <Results results={results} sources={sources} />
      </LoadingWrapper>
    </div>
  )
}

export default SearchResults
