import Head from 'next/head'
import React, { ReactNode } from 'react'

import { SITE_DESCRIPTION, SITE_NAME, SITE_URL } from '../../../config'

export type SEOProps = {
  /**
   * Content
   */
  children?: ReactNode | string
  /**
   * Title for site name
   */
  title?: string
  /**
   * description for meta tag
   */
  description?: string
  /**
   * og:image/twitter:image tags
   */
  picture?: string
  /**
   * og:url tag
   */
  url?: string
  /**
   * og:video/twitter:player tags
   */
  video?: string
  /**
   * author tag
   */
  author?: string
  /**
   * show extra details for title
   */
  showSiteName?: boolean
}

const SEO = ({
  children,
  title: _title,
  description,
  picture,
  url: _url,
  video: _video,
  author,
  showSiteName = true,
}: SEOProps) => {
  let title
  const video = _video

  const url = SITE_URL + (_url || '')

  if (showSiteName) {
    title = _title ? `${_title} | ${SITE_NAME}` : SITE_NAME
  } else {
    title = _title
  }

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Schema.org markup for Google+ */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      {author ? <meta name="author" content={author} /> : null}

      {/* Twitter Card Data */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* Twitter summary card with large image must be at least 280 x 150px */}
      {/* Recommendation: Minimum size: 300 x 157px. Maximum size: 4096 x 4096px  */}
      {/* Recommendation: Use a 2:1 aspect ratio image (eg: 1200 x 600px) */}
      {/* See: https://iamturns.com/open-graph-image-size/ */}
      <meta name="twitter:image" content={picture} />
      {video ? <meta name="twitter:player" content={video} /> : null}

      {/* Open Graph Data */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      {/* The recommended image size is 1200 x 630px */}
      <meta property="og:image" content={picture} />
      {video ? <meta property="og:video" content={video} /> : null}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={SITE_NAME} />
      {children}
    </Head>
  )
}

SEO.defaultProps = {
  description: SITE_DESCRIPTION,
  picture: SITE_URL + '/static/images/banner_old.png',
}

export default SEO
