import { format } from 'date-fns'
import Link from 'next/link'
import useSWR from 'swr'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import SkeletonBlock from '@/components/base/SkeletonBlock'
import Avatar from '@/components/shared/Avatar'
import { getAuthorByUUID } from '@/services/authorService'
import { EventsType } from '@/ts/types/events.types'
import { getDateFnsLocale } from '@/utils/dates'
import { eventsType } from '@/utils/events'
import { getLocaleText } from '@/utils/i18n'

type EventItemProps = {
  event: EventsType
}

const EventSlimItem = ({ event }: EventItemProps) => {
  const { data: company, isLoading } = useSWR({ authorUUID: event.CompanyUUID }, getAuthorByUUID)

  const actualEventType = eventsType[event?.Type]

  return (
    <Link
      href={`/events/${event.UUID}`}
      className="flex flex-row gap-2 p-2 w-full hover:bg-gray-100 rounded-xl cursor-pointer"
    >
      <div className="bg-accent-1 px-4 py-3 flex flex-col items-center justify-center h-full rounded-2xl">
        <CustomText className="text-white capitalize">
          {format(new Date(event?.StartsAt), 'MMM', {
            locale: getDateFnsLocale(),
          })}
        </CustomText>
        <CustomText className="text-white" weight={FontWeight.SemiBold} size={TextSize.SubTitle2}>
          {format(new Date(event?.StartsAt), 'dd', {
            locale: getDateFnsLocale(),
          })}
        </CustomText>
      </div>
      <div className="flex-1 flex flex-col gap-1 justify-center">
        <div>
          <div className="flex flex-row justify-between">
            <CustomText weight={FontWeight.SemiBold} className="text-yellow ">
              {format(new Date(event?.StartsAt), 'hh:mm', {
                locale: getDateFnsLocale(),
              })}{' '}
              -{' '}
              {format(new Date(event?.EndsAt), 'hh:mm', {
                locale: getDateFnsLocale(),
              })}
            </CustomText>
            <div className="flex flex-row items-center gap-1">
              <div className="px-3 py-1 bg-secondary rounded-3xl">
                <CustomText size={TextSize.Tiny} className="text-white">
                  {actualEventType}
                </CustomText>
              </div>
            </div>
          </div>
          <CustomText weight={FontWeight.SemiBold} className="text-start text-black">
            {getLocaleText(event.Name)}
          </CustomText>
        </div>

        <div className="flex flex-row items-center gap-1">
          {isLoading ? (
            <>
              <SkeletonBlock className="rounded-full" width={20} height={20} uniqueKey="avatar-item" />
              <SkeletonBlock className="rounded-md" width={80} height={15} uniqueKey="name-item" />
            </>
          ) : (
            <>
              <Avatar
                text={company?.Name}
                className="w-6 h-6"
                borderWidth="sm"
                levelColor={company?.LevelColor}
                image={company?.Photo?.Thumbnail}
              />
              <CustomText weight={FontWeight.SemiBold} className="text-gray-2" size={TextSize.Caption}>
                {company?.Name}
              </CustomText>
            </>
          )}
        </div>
      </div>
    </Link>
  )
}

export default EventSlimItem
