export const categoryDetails = {
  user: {
    'Transporte H2O': {
      src: '/static/images/cft/categories/transport.svg',
    },
    'Transporte CO2': {
      src: '/static/images/cft/categories/transport.svg',
    },
    'Electricidad CO2': {
      src: '/static/images/cft/categories/electricity.svg',
    },
    'Electricidad H2O': {
      src: '/static/images/cft/categories/electricity.svg',
    },
    Streaming: {
      src: '/static/images/cft/categories/streaming.svg',
    },
    Moda: { src: '/static/images/cft/categories/fashion.svg' },
    'Comida CO2': {
      src: '/static/images/cft/categories/food.svg',
    },
    'Comida H2O': {
      src: '/static/images/cft/categories/food-2.svg',
    },
    'Compras CO2': {
      src: '/static/images/cft/categories/shopping.svg',
    },
    'Compras H2O': {
      src: '/static/images/cft/categories/shopping-2.svg',
    },
    'CO2 Negativas': {
      src: '/static/images/cft/categories/CO2negativa.svg',
    },
    'H2O Negativas': {
      src: '/static/images/cft/categories/H2Oahorra.svg',
    },
    'Agua Visible': {
      src: '/static/images/cft/categories/agua_visible.svg',
    },
    'Agua Virtual': {
      src: '/static/images/cft/categories/agua_virtual.svg',
    },
  },
  company: {
    Envíos: {
      src: '/static/images/cft/categories/transport.svg',
    },
    'Carril (Co2e)': {
      src: '/static/images/cft/categories/TransportCO2/train.png',
    },
    'Vía aérea': {
      src: '/static/images/cft/categories/TransportCO2/plane.png',
    },
    'Flota de vehiculos': {
      src: '/static/images/cft/categories/TransportCO2/car-combustion.png',
    },
    Calefacción: {
      src: '/static/images/cft/categories/combustion.svg',
    },
    Electricidad: {
      src: '/static/images/cft/categories/electricity.svg',
    },
    'Electricidad CO2': {
      src: '/static/images/cft/categories/electricity.svg',
    },
    'Electricidad H2O': {
      src: '/static/images/cft/categories/electricity.svg',
    },
  },
}

export const itemsDetails = {
  user: {
    'Transporte CO2': {
      Automóvil: {
        src: '/static/images/cft/categories/TransportCO2/car-combustion.png',
      },
      Bus: { src: '/static/images/cft/categories/TransportCO2/bus.png' },
      Avión: { src: '/static/images/cft/categories/TransportCO2/plane.png' },
      Tren: { src: '/static/images/cft/categories/TransportCO2/train.png' },
      Barco: { src: '/static/images/cft/categories/TransportCO2/ship.png' },
      Motocicleta: {
        src: '/static/images/cft/categories/TransportCO2/motorcycle.png',
      },
      automática: { src: '/static/images/cft/categories/transport.svg' },
    },
    'Transporte H2O': {
      'Auto / Compartir auto': {
        src: '/static/images/cft/categories/TransportH2O/sharing.png',
      },
      'Tren (eléctrico)': {
        src: '/static/images/cft/categories/TransportCO2/train.png',
      },
    },
    'Comida CO2': {
      'Carne de Vacuno': {
        src: '/static/images/cft/categories/FoodCO2/vacuno.png',
      },
      Cordero: { src: '/static/images/cft/categories/FoodCO2/cordero.png' },
      'Carne de cerdo': {
        src: '/static/images/cft/categories/FoodCO2/cerdo.png',
      },
      Pollo: { src: '/static/images/cft/categories/FoodCO2/pollo.png' },
      Pavo: { src: '/static/images/cft/categories/FoodCO2/pavo.png' },
      Pescado: { src: '/static/images/cft/categories/FoodCO2/atun.png' },
      Café: { src: '/static/images/cft/categories/FoodCO2/coffee.png' },
      Huevos: { src: '/static/images/cft/categories/FoodCO2/egg.png' },
      Papas: { src: '/static/images/cft/categories/FoodCO2/potato.png' },
      Arroz: { src: '/static/images/cft/categories/FoodCO2/arroz.png' },
      'Frutos Secos': {
        src: '/static/images/cft/categories/FoodCO2/frutos-secos.png',
      },
      Vegetales: {
        src: '/static/images/cft/categories/FoodCO2/verduras.png',
      },
      Fruta: { src: '/static/images/cft/categories/FoodCO2/frutas.png' },
      Queso: { src: '/static/images/cft/categories/FoodCO2/queso.png' },
      Leche: { src: '/static/images/cft/categories/FoodCO2/milk-bottle.png' },
      Chocolate: {
        src: '/static/images/cft/categories/FoodCO2/chocolate.png',
      },
      Legumbres: { src: '/static/images/cft/categories/FoodCO2/legumbres.png' },
      Varios: { src: '/static/images/cft/categories/food.svg' },
    },
    'Comida H2O': {
      Hamburgesa: {
        src: '/static/images/cft/categories/FoodH2O/cheeseburger.png',
      },
      'Carne de Vacuno': {
        src: '/static/images/cft/categories/FoodCO2/vacuno.png',
      },
      Pescado: { src: '/static/images/cft/categories/FoodCO2/atun.png' },
      Cordero: { src: '/static/images/cft/categories/FoodCO2/cordero.png' },
      Pollo: { src: '/static/images/cft/categories/FoodCO2/pollo.png' },
      Pavo: { src: '/static/images/cft/categories/FoodCO2/pavo.png' },
      Chocolate: {
        src: '/static/images/cft/categories/FoodCO2/chocolate.png',
      },
      Papas: { src: '/static/images/cft/categories/FoodCO2/potato.png' },
      Arroz: { src: '/static/images/cft/categories/FoodCO2/arroz.png' },
      'Frutos Secos': {
        src: '/static/images/cft/categories/FoodCO2/frutos-secos.png',
      },
      Vegetales: {
        src: '/static/images/cft/categories/FoodCO2/verduras.png',
      },
      Fruta: { src: '/static/images/cft/categories/FoodCO2/frutas.png' },
      Queso: { src: '/static/images/cft/categories/FoodCO2/queso.png' },
      Leche: { src: '/static/images/cft/categories/FoodCO2/milk-bottle.png' },
      Legumbres: { src: '/static/images/cft/categories/FoodCO2/legumbres.png' },
      Pan: {
        src: '/static/images/cft/categories/FoodH2O/cheeseburger.png',
      },
      Te: {
        src: '/static/images/cft/categories/FoodCO2/coffee.png',
      },
      'Carne de cerdo': {
        src: '/static/images/cft/categories/FoodH2O/meatloaf.png',
      },
      Bistec: { src: '/static/images/cft/categories/FoodH2O/steak.png' },
      Huevos: { src: '/static/images/cft/categories/FoodCO2/egg.png' },
      Bebida: { src: '/static/images/cft/categories/FoodH2O/soft-drink.png' },
      Café: { src: '/static/images/cft/categories/FoodCO2/coffee.png' },
      Vino: { src: '/static/images/cft/categories/FoodH2O/wine.png' },
      Ensalada: { src: '/static/images/cft/categories/FoodH2O/salad.png' },
      Varios: { src: '/static/images/cft/categories/food.svg' },
    },
    'Compras CO2': {
      Smartphone: {
        src: '/static/images/cft/categories/ShoppingCO2/smart.png',
      },
      Notebook: { src: '/static/images/cft/categories/ShoppingCO2/laptop.png' },
      Tablet: { src: '/static/images/cft/categories/ShoppingCO2/tablet.png' },
      Computador: {
        src: '/static/images/cft/categories/ShoppingCO2/computer.png',
      },
      TV: { src: '/static/images/cft/categories/ShoppingCO2/smart-tv.png' },
      'Automóvil Eléctrico': {
        src: '/static/images/cft/categories/ShoppingCO2/Electrico.png',
      },
      'Automóvil a combustión': {
        src: '/static/images/cft/categories/TransportCO2/car-combustion.png',
      },
      'Automóvil Híbrido': {
        src: '/static/images/cft/categories/ShoppingCO2/Hibrido.png',
      },
      'Transacción de bitcoin': {
        src: '/static/images/cft/categories/ShoppingCO2/bitcoin.png',
      },
    },
    'Compras H2O': {
      'Zapatos de cuero': {
        src: '/static/images/cft/categories/Fashion/running-shoes.png',
      },
      Celular: {
        src: '/static/images/cft/categories/ShoppingCO2/smart.png',
      },
      'Sábanas (algodón)': {
        src: '/static/images/cft/categories/ShoppingH2O/bed-sheets.png',
      },
      Jeans: {
        src: '/static/images/cft/categories/ShoppingH2O/jeans.png',
      },
      'Polera (algodón)': {
        src: '/static/images/cft/categories/ShoppingH2O/t-shirt.png',
      },
      Varios: { src: '/static/images/cft/categories/shopping-2.svg' },
    },
    Streaming: {
      'Música (Audio)': {
        src: '/static/images/cft/categories/Streaming/podcast.png',
      },
      'Video HD (720p)': {
        src: '/static/images/cft/categories/Streaming/hd-player.png',
      },
      'Video Full HD (1080p)': {
        src: '/static/images/cft/categories/Streaming/hd.png',
      },
      'Video Ultra HD (4k)': {
        src: '/static/images/cft/categories/Streaming/4k.png',
      },
    },
    'Electricidad CO2': {
      'Consumo Mensual Kwh': {
        src: '/static/images/cft/categories/ElectricityCO2/eco-house.png',
      },
    },
    'Electricidad H2O': {
      Electricidad: {
        src: '/static/images/cft/categories/ElectricityH2O/energy.png',
      },
    },
    Moda: {
      Abrigo: { src: '/static/images/cft/categories/Fashion/trench-coat.png' },
      Vestido: { src: '/static/images/cft/categories/Fashion/summer.png' },
      Jeans: { src: '/static/images/cft/categories/ShoppingH2O/jeans.png' },
      Camisa: { src: '/static/images/cft/categories/Fashion/shirt.png' },
      Polera: { src: '/static/images/cft/categories/ShoppingH2O/t-shirt.png' },
      Sueter: { src: '/static/images/cft/categories/Fashion/sweater.png' },
      Zapatos: {
        src: '/static/images/cft/categories/Fashion/running-shoes.png',
      },
    },
    'Agua Visible': {
      'Ducha flujo bajo': {
        src: '/static/images/cft/categories/VisibleH2O/flujo-estandar-bajo.png',
      },
      'Ducha flujo estándar': {
        src: '/static/images/cft/categories/VisibleH2O/flujo-estandar-bajo.png',
      },
      'Tina flujo bajo': {
        src: '/static/images/cft/categories/VisibleH2O/tina-flujo-bajo-estandar.png',
      },
      'Tina flujo estándar': {
        src: '/static/images/cft/categories/VisibleH2O/tina-flujo-bajo-estandar.png',
      },
      'Inodoro flujo bajo': {
        src: '/static/images/cft/categories/VisibleH2O/wc-bajo-estandar.png',
      },
      'Inodoro flujo estándar': {
        src: '/static/images/cft/categories/VisibleH2O/wc-bajo-estandar.png',
      },
      'Regar pasto/jardín': {
        src: '/static/images/cft/categories/VisibleH2O/hose.png',
      },
      'Lavado de ropa flujo bajo': {
        src: '/static/images/cft/categories/VisibleH2O/lavadora-estandar-bajo.png',
      },
      'Lavado de ropa flujo estándar': {
        src: '/static/images/cft/categories/VisibleH2O/lavadora-estandar-bajo.png',
      },
      'Lavavajillas flujo bajo': {
        src: '/static/images/cft/categories/VisibleH2O/lavaloza-estandar-bajo.png',
      },
      'Lavavajillas flujo estándar': {
        src: '/static/images/cft/categories/VisibleH2O/lavaloza-estandar-bajo.png',
      },
      'Llave de cocina/baño flujo bajo': {
        src: '/static/images/cft/categories/VisibleH2O/llave-flujo-bajo-estandar.png',
      },
      'Llave de cocina/baño flujo estándar': {
        src: '/static/images/cft/categories/VisibleH2O/llave-flujo-bajo-estandar.png',
      },
      'Lavado de auto': {
        src: '/static/images/cft/categories/VisibleH2O/lavar-auto.png',
      },
      'Promedio mensual': { src: '/static/images/cft/categories/agua_visible.svg' },
    },
    'CO2 Negativas': {
      'Árbol típico maduro': {
        src: '/static/images/cft/categories/CO2Negative/tree2.png',
      },
      'Árbol pequeño inmaduro': {
        src: '/static/images/cft/categories/CO2Negative/tree1.png',
      },
      'Reciclar Papel/Cartón': {
        src: '/static/images/cft/categories/CO2Negative/paper.png',
      },
      'Reciclar vidrio': {
        src: '/static/images/cft/categories/CO2Negative/glass.png',
      },
      'Reciclar plástico': {
        src: '/static/images/cft/categories/CO2Negative/plastic.png',
      },
      'Reciclar aluminio': {
        src: '/static/images/cft/categories/CO2Negative/aluminum.png',
      },
    },
    'H2O Negativas': {
      'Sistema de aguas grises': {
        src: '/static/images/cft/categories/H2ONegative/save-water.png',
      },
      'Reciclar Papel/Cartón': {
        src: '/static/images/cft/categories/CO2Negative/paper.png',
      },
      'Reciclar aluminio': {
        src: '/static/images/cft/categories/CO2Negative/aluminum.png',
      },
      'Barril de lluvia': {
        src: '/static/images/cft/categories/H2ONegative/barrel.png',
      },
    },
    'Emisión autogenerada': {
      default: { src: '/static/images/cft/categories/Fashion/trench-coat.png' },
    },
  },
  company: {
    Envíos: {
      Aire: {
        src: '/static/images/cft/categories/TransportCO2/plane.png',
      },
      'Camión/Tierra': {
        src: '/static/images/cft/categories/TransportCO2/car-combustion.png',
      },
      Tren: {
        src: '/static/images/cft/categories/TransportCO2/train.png',
      },
      'Océano/Agua': {
        src: '/static/images/cft/categories/TransportCO2/ship.png',
      },
    },
    'Carril (Co2e)': {
      'Viajes en tren': {
        src: '/static/images/cft/categories/TransportCO2/train.png',
      },
    },
    'Vía aérea': {
      'Vía aérea': {
        src: '/static/images/cft/categories/TransportCO2/plane.png',
      },
    },
    'Flota de vehiculos': {
      Gasolina: {
        src: '/static/images/cft/categories/TransportCO2/car-combustion.png',
      },
      Diesel: {
        src: '/static/images/cft/categories/TransportCO2/car-combustion.png',
      },
    },
    Calefacción: {
      'Gas Natural': {
        src: '/static/images/cft/categories/blue-fire.svg',
      },
      Aceite: {
        src: '/static/images/cft/categories/oil-barrel.svg',
      },
      Propano: {
        src: '/static/images/cft/categories/gas-bottle.svg',
      },
    },
    Electricidad: {
      Electricidad: {
        src: '/static/images/cft/categories/electricity.svg',
      },
      'Agua Virtual (Indirecta)': {
        src: '/static/images/cft/categories/agua_virtual.svg',
      },
    },
    'Electricidad CO2': {
      Electricidad: {
        src: '/static/images/cft/categories/electricity.svg',
      },
    },
    'Electricidad H2O': {
      Electricidad: {
        src: '/static/images/cft/categories/electricity.svg',
      },
      'Agua Virtual (Indirecta)': {
        src: '/static/images/cft/categories/agua_virtual.svg',
      },
    },
  },
}

export const cftCarbonCountries = [
  {
    country: {
      es: 'Australia',
      en: 'Australia',
    },
    carbon: 1359.17,
    water: 189,
    img: '/static/images/cft/countries/234-australia.svg',
  },
  {
    country: {
      es: 'EEUU',
      en: 'EEUU',
    },
    carbon: 1338.33,
    water: 234,
    img: '/static/images/cft/countries/226-united-states.svg',
  },
  {
    country: {
      es: 'Rusia',
      en: 'Russia',
    },
    water: 153,
    carbon: 959.17,
    img: '/static/images/cft/countries/248-russia.svg',
  },
  {
    country: {
      es: 'Japón',
      en: 'Japan',
    },
    carbon: 726.67,
    water: 114,
    img: '/static/images/cft/countries/063-japan.svg',
  },
  {
    country: {
      es: 'Alemania',
      en: 'Germany',
    },
    water: 117,
    carbon: 700.0,
    img: '/static/images/cft/countries/162-germany.svg',
  },
  {
    country: {
      es: 'China',
      en: 'China',
    },
    water: 87,
    carbon: 591.67,
    img: '/static/images/cft/countries/034-china.svg',
  },
  {
    country: {
      es: 'Francia',
      en: 'France',
    },
    water: 147,
    carbon: 414.17,
    img: '/static/images/cft/countries/195-france.svg',
  },
  {
    country: {
      es: 'Chile',
      en: 'Chile',
    },
    water: 96,
    carbon: 370.83,
    img: '/static/images/cft/countries/131-chile.svg',
  },
  {
    country: {
      es: 'México',
      en: 'Mexico',
    },
    carbon: 286.67,
    water: 162,
    img: '/static/images/cft/countries/252-mexico.svg',
  },
  {
    country: {
      es: 'India',
      en: 'India',
    },
    carbon: 159.17,
    water: 90,
    img: '/static/images/cft/countries/246-india.svg',
  },
]

export const cftIndustries = [
  {
    industry: {
      es: 'Minería',
      en: 'Mining',
    },
    carbon: 238,
    water: 189,
    img: '/static/images/cft/industries/helmet.svg',
    extraUnit: 'Mil',
  },
  {
    industry: {
      es: 'Textil',
      en: 'Textile',
    },
    carbon: 141,
    water: 189,
    img: '/static/images/cft/industries/thread-spool.svg',
    extraUnit: 'Millones de',
  },
  {
    industry: {
      es: 'Construcción',
      en: 'Construction',
    },
    carbon: 441,
    water: 189,
    img: '/static/images/cft/industries/crane.svg',
    extraUnit: 'Mil',
  },
  {
    industry: {
      es: 'Alimentación',
      en: 'Food',
    },
    carbon: 1140,
    water: 189,
    img: '/static/images/cft/industries/assembly-line.svg',
    extraUnit: 'Millones de',
  },
  {
    industry: {
      es: 'Tecnología',
      en: 'Technology',
    },
    carbon: 1.25,
    water: 189,
    img: '/static/images/cft/industries/chip.svg',
    extraUnit: 'Millones de',
  },
]

export const cftIndustriesH20 = [
  {
    industry: {
      es: 'Minería',
      en: 'Mining',
    },
    water: 468.723,
    img: '/static/images/cft/industries/helmet.svg',
    extraUnit: 'Mil',
  },
  {
    industry: {
      es: 'SAP',
      en: 'SAP',
    },
    water: 21784.08,
    img: '/static/images/cft/industries/assembly-line.svg',
    extraUnit: 'Mil',
  },
  {
    industry: {
      es: 'Energía',
      en: 'Energy',
    },
    water: 183.151,
    img: '/static/images/cft/industries/chip.svg',
    extraUnit: 'Mil',
  },
  {
    industry: {
      es: 'Industria',
      en: 'Industry',
    },
    water: 431.583,
    img: '/static/images/cft/industries/crane.svg',
    extraUnit: 'Mil',
  },
]

export const GoalsValues = {
  carbon: {
    min: 166,
    warn: 700,
    max: 1500,
  },
  hydro: {
    min: 32,
    warn: 64,
    max: 128,
  },
}

export const IndustryGoalValues = {
  carbon: {
    min: 166,
    warn: 1500,
    max: 3000,
  },
  hydro: {
    min: 32,
    warn: 1000,
    max: 2000,
  },
}

export const addOrderDateField = (goals) => {
  return goals.map((goal) => {
    goal['OrderDate'] = new Date(goal?.Month?.slice(4, 6), goal?.Month?.slice(0, 4), 1)
    return goal
  })
}

export const orderByOrderDateField = (goals) => {
  return goals.sort((a, b) => b.OrderDate - a.OrderDate)
}

export const getGoalOfActualMonth = (goals) => {
  const today = new Date()
  const actualMonth = today.getMonth() + 1
  const actualYear = today.getFullYear()
  let selectedGoal = null
  for (const goal of goals) {
    const goalMonth = parseInt(goal?.Month?.slice(4, 6), 10)
    const goalYear = parseInt(goal?.Month?.slice(0, 4), 10)

    if (goalMonth === actualMonth && actualYear === goalYear) {
      selectedGoal = goal
      break
    }
  }
  return selectedGoal
}

export const getPercentageOfGoal = (goal, type) => {
  if (!goal) {
    return 0
  }

  switch (type) {
    case 'carbon':
      return (goal.CurrentKG * 100) / goal.TotalKG
    case 'water':
      return ((goal.CurrentLT / 1000) * 100) / goal.TotalLT
    default:
      return (goal.CurrentKG * 100) / goal.TotalKG
  }
}

export const getPercentageOfGoalCompany = (goal, type) => {
  if (!goal) {
    return 0
  }

  switch (type) {
    case 'carbon':
      return ((goal.CurrentKG / 1000) * 100) / goal.TotalKG
    case 'water':
      return ((goal.CurrentLT / 1000) * 100) / goal.TotalLT
    default:
      return ((goal.CurrentKG / 1000) * 100) / goal.TotalKG
  }
}

export const getCalculatorPercentageOfGoal = (goal, type, emission) => {
  if (!goal) {
    return 0
  }

  switch (type) {
    case 'carbon':
      return (emission.CurrentKG * 100) / goal.TotalKG
    case 'water':
      return ((emission.CurrentLT / 1000) * 100) / goal.TotalLT
    default:
      return (emission.CurrentKG * 100) / goal.TotalKG
  }
}
