import Countries from './../public/countries.json'

export const getCountryFromName = (name: string) => {
  return Countries.find((country) => country.name === name)
}

export const AllCountries = {
  af: 'Afganistán',
  al: 'Albania',
  de: 'Alemania',
  ad: 'Andorra',
  ao: 'Angola',
  ag: 'Antigua y Barbuda',
  sa: 'Arabia Saudita',
  dz: 'Argelia',
  ar: 'Argentina',
  am: 'Armenia',
  au: 'Australia',
  at: 'Austria',
  az: 'Azerbaiyán',
  bs: 'Bahamas',
  bh: 'Bahrein',
  bd: 'Bangladesh',
  bb: 'Barbados',
  by: 'Belarús',
  bz: 'Belice',
  bj: 'Benin',
  bt: 'Bhután',
  bo: 'Bolivia',
  ba: 'Bosnia y Herzegovina',
  bw: 'Botswana',
  br: 'Brasil',
  bn: 'Brunei Darussalam',
  bg: 'Bulgaria',
  bf: 'Burkina Faso',
  bi: 'Burundi',
  be: 'Bélgica',
  cv: 'Cabo Verde',
  kh: 'Camboya',
  cm: 'Camerún',
  ca: 'Canadá',
  td: 'Chad',
  cz: 'Chequia',
  cl: 'Chile',
  cn: 'China',
  cy: 'Chipre',
  co: 'Colombia',
  km: 'Comoras',
  cg: 'Congo',
  cr: 'Costa Rica',
  hr: 'Croacia',
  cu: 'Cuba',
  ci: "Côte d'Ivoire",
  dk: 'Dinamarca',
  dj: 'Djibouti',
  dm: 'Dominica',
  ec: 'Ecuador',
  eg: 'Egipto',
  sv: 'El Salvador',
  ae: 'Emiratos Árabes Unidos',
  er: 'Eritrea',
  sk: 'Eslovaquia',
  si: 'Eslovenia',
  es: 'España',
  us: 'Estados Unidos de América',
  ee: 'Estonia',
  sz: 'Eswatini',
  et: 'Etiopía',
  ru: 'Federación de Rusia',
  fj: 'Fiji',
  ph: 'Filipinas',
  fi: 'Finlandia',
  fr: 'Francia',
  ga: 'Gabón',
  gm: 'Gambia',
  ge: 'Georgia',
  gh: 'Ghana',
  gd: 'Granada',
  gr: 'Grecia',
  gt: 'Guatemala',
  gn: 'Guinea',
  gq: 'Guinea Ecuatorial',
  gw: 'Guinea-Bissau',
  gy: 'Guyana',
  ht: 'Haití',
  hn: 'Honduras',
  hu: 'Hungría',
  in: 'India',
  id: 'Indonesia',
  iq: 'Iraq',
  ie: 'Irlanda',
  ir: 'Irán',
  is: 'Islandia',
  ck: 'Islas Cook',
  fo: 'Islas Feroe',
  mh: 'Islas Marshall',
  sb: 'Islas Salomón',
  il: 'Israel',
  it: 'Italia',
  jm: 'Jamaica',
  jp: 'Japón',
  jo: 'Jordania',
  kz: 'Kazajstán',
  ke: 'Kenya',
  kg: 'Kirguistán',
  ki: 'Kiribati',
  kw: 'Kuwait',
  ls: 'Lesotho',
  lv: 'Letonia',
  lr: 'Liberia',
  ly: 'Libia',
  lt: 'Lituania',
  lu: 'Luxemburgo',
  lb: 'Líbano',
  mk: 'Macedonia del Norte',
  mg: 'Madagascar',
  my: 'Malasia',
  mw: 'Malawi',
  mv: 'Maldivas',
  mt: 'Malta',
  ml: 'Malí',
  ma: 'Marruecos',
  mu: 'Mauricio',
  mr: 'Mauritania',
  fm: 'Micronesia',
  mn: 'Mongolia',
  me: 'Montenegro',
  mz: 'Mozambique',
  mm: 'Myanmar',
  mx: 'México',
  mc: 'Mónaco',
  na: 'Namibia',
  nr: 'Nauru',
  np: 'Nepal',
  ni: 'Nicaragua',
  ng: 'Nigeria',
  nu: 'Niue',
  no: 'Noruega',
  nz: 'Nueva Zelandia',
  ne: 'Níger',
  om: 'Omán',
  pk: 'Pakistán',
  pw: 'Palau',
  pa: 'Panamá',
  pg: 'Papua Nueva Guinea',
  py: 'Paraguay',
  nl: 'Países Bajos',
  pe: 'Perú',
  pl: 'Polonia',
  pt: 'Portugal',
  qa: 'Qatar',
  gb: 'Reino Unido de Gran Bretaña e Irlanda del Norte',
  cf: 'República Centroafricana',
  la: 'República Democrática Popular Lao',
  cd: 'República Democrática del Congo',
  do: 'República Dominicana',
  kp: 'República Popular Democrática de Corea',
  tz: 'República Unida de Tanzanía',
  kr: 'República de Corea',
  md: 'República de Moldova',
  sy: 'República Árabe Siria',
  ro: 'Rumania',
  rw: 'Rwanda',
  kn: 'Saint Kitts y Nevis',
  ws: 'Samoa',
  sm: 'San Marino',
  vc: 'San Vicente y las Granadinas',
  lc: 'Santa Lucía',
  st: 'Santo Tomé y Príncipe',
  sn: 'Senegal',
  rs: 'Serbia',
  sc: 'Seychelles',
  sl: 'Sierra Leona',
  sg: 'Singapur',
  so: 'Somalia',
  lk: 'Sri Lanka',
  za: 'Sudáfrica',
  sd: 'Sudán',
  ss: 'Sudán del Sur',
  se: 'Suecia',
  ch: 'Suiza',
  sr: 'Suriname',
  th: 'Tailandia',
  tj: 'Tayikistán',
  tl: 'Timor-Leste',
  tg: 'Togo',
  tk: 'Tokelau',
  to: 'Tonga',
  tt: 'Trinidad y Tabago',
  tm: 'Turkmenistán',
  tr: 'Turquía',
  tv: 'Tuvalu',
  tn: 'Túnez',
  ua: 'Ucrania',
  ug: 'Uganda',
  uy: 'Uruguay',
  uz: 'Uzbekistán',
  vu: 'Vanuatu',
  ve: 'Venezuela',
  vn: 'Viet Nam',
  ye: 'Yemen',
  zm: 'Zambia',
  zw: 'Zimbabwe',
}

export const AllCountryNames = Object.values(AllCountries)
