import Image from 'next/image'
import { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'

import { BadgeModalBackground } from '@/components/base/BadgeModal/BadgeModalBackground'
import { NoMarginModal } from '@/components/base/NoMarginModal'
import useBadgeStatus from '@/hooks/useBadgeStatus'
import { useRouter } from 'next/router'
import useSunetAuthors from '@/hooks/useSunetAuthors'

export const BadgeModal = () => {
  const { push } = useRouter()
  const { author } = useSunetAuthors()
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const { badges } = useBadgeStatus({ openModal })

  const hasBadges = badges?.length > 0
  const hasMoreBadges = badges?.length > 1
  const [currentBadgeIndex, setCurrentBadgeIndex] = useState(0)

  const isLast = currentBadgeIndex + 1 === badges?.length

  const currentBadge = badges[currentBadgeIndex]

  useEffect(() => {
    if (hasBadges) {
      setCurrentBadgeIndex(0)
    }
  }, [badges])

  const handleNext = () => {
    setCurrentBadgeIndex((prev) => prev + 1)
  }

  const closeModal = () => {
    if (hasMoreBadges) {
      if (isLast) {
        setOpen(false)
      }
      if (!isLast) {
        setOpen(false)
        setTimeout(handleNext, 500)
        setTimeout(openModal, 1000)
      }
    } else {
      setOpen(false)
    }
  }

  return (
    <NoMarginModal isOpen={open} setIsOpen={setOpen}>
      <div className="relative flex items-center justify-center">
        <button onClick={closeModal} className="bg-gray-4 p-2 rounded-full absolute right-2 top-2 z-60">
          <FaTimes />
        </button>
        <div
          onClick={() => push(`/profile/u/${author.UUID}/medals`)}
          className="absolute flex flex-col items-center justify-center top-[22%] xs:top-[25%] sm:top-[10%] cursor-pointer"
        >
          <span className="font-bold text-xl sm:text-3xl text-center text-[#0B88AF] whitespace-pre-wrap">{`¡Ganaste una\n medalla!`}</span>
          <div className="sm:w-[150px] sm:h-[150px] w-[100px] h-[100px]">
            <Image alt="Badge" width={150} height={150} src={currentBadge?.Image} />
          </div>
          <span className="font-bold text-xl sm:text-xl text-center text-[#0B88AF] max-w-[200px]">
            {currentBadge?.Name?.es}
          </span>
        </div>
        <BadgeModalBackground />
      </div>
    </NoMarginModal>
  )
}
