import { useEffect, useState } from 'react'

import { checkNewBadges, markBadgeAsRead } from '@/services/gamificationService'

const ONLINE_STATUS_FETCH_TIME = 300000

const useBadgeStatus = ({ openModal }) => {
  const [badges, setBadges] = useState([])

  const checkForBadges = async () => {
    try {
      const res = await checkNewBadges()

      if (res?.length > 0) {
        setBadges(res)
        openModal()
        await Promise.all(res.map((badge) => markBadgeAsRead({ ChallengeUUID: badge.ChallengeUUID })))
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    checkForBadges()
    const intervalId = setInterval(() => {
      checkForBadges()
    }, ONLINE_STATUS_FETCH_TIME)

    return () => clearInterval(intervalId)
  }, [])

  return { badges }
}

export default useBadgeStatus
