import { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import useSWR from 'swr'

import CustomText from '@/components/base/CustomText'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import { getConnectedFollowees } from '@/services/authorService'

import Avatar from '../../Avatar'
import MessageModal from '../../MessageModal'
import VerticalReveal from '../../VerticalReveal'
import { ConnectedItem } from './ConnectedItem'

export const ConnectedFollowees = () => {
  const { data } = useSWR('/connected-followers', getConnectedFollowees, { refreshInterval: 60000 })
  const connectedUsers = data?.data?.Data

  const [open, setOpen] = useState(false)
  const [openChat, setOpenChat] = useState(false)
  const [chatUUID, setChatUUID] = useState(null)
  const { author } = useSunetAuthors()

  return (
    <div
      className={`hidden md:block fixed z-50 ring-1 rounded-t-xl ring-gray-300 bg-background overflow-hidden`}
      style={{ right: '2%', bottom: '0' }}
    >
      <div
        onClick={() => setOpen(!open)}
        className="flex gap-12 items-center px-4 py-3 bg-white border-b border-gray-300 cursor-pointer"
      >
        <div className="flex space-x-2 items-center">
          <div className="relative">
            <div className="h-2 w-2 bg-primary rounded-full bottom-0 absolute z-10 right-0 ring-2 ring-white" />
            <Avatar
              className="h-6 w-6 !rounded-full"
              image={author?.Photo?.Thumbnail}
              showBorder={false}
              borderWidth="sm"
            />
          </div>
          <CustomText>Conectados ({connectedUsers?.length || 0})</CustomText>
        </div>
        {!open ? <FiChevronUp /> : <FiChevronDown />}
      </div>
      <VerticalReveal open={open}>
        <section className="flex flex-col space-y-2 divide-y-2 bg-background w-full mb-1 overflow-y-scroll">
          {connectedUsers?.length ? (
            connectedUsers.map((item) => (
              <ConnectedItem
                onClick={() => {
                  setOpenChat(true)
                  setChatUUID(item.UUID)
                }}
                key={item.UUID}
                connectedFollower={item}
              />
            ))
          ) : (
            <div className="p-2 text-center">
              <p>No hay usuarios conectados</p>
            </div>
          )}
        </section>
      </VerticalReveal>
      <MessageModal open={openChat} setOpen={setOpenChat} participantUUID={chatUUID} />
    </div>
  )
}
