import { useEffect, useState } from 'react'
import useSWR from 'swr'

import { createGoal, findAllGoalsByAuthorUUID } from '@/services/footprintService'
import { GoalType } from '@/ts/types/footprint.types'
import { SunetProfile, SunetUser } from '@/ts/types/user.types'
import { getGoalOfActualMonth } from '@/utils/cftCategories'

import useRequest from './useRequest'

type Props = {
  initialData?: SunetUser
  AuthorUUID?: string
}

type ReturnProps = {
  profile: SunetProfile
  loading: boolean
  actualGoal: GoalType
}

const useCftGoal = ({ AuthorUUID }: Props): ReturnProps => {
  const { execWithCallback, loading: createLoading } = useRequest(createGoal)

  const { data: Goals, isLoading } = useSWR(AuthorUUID ? AuthorUUID : null, findAllGoalsByAuthorUUID, {
    revalidateOnFocus: false,
  })

  const [lastGoal, setLastGoal] = useState<GoalType>()
  const [actualGoal, setActualGoal] = useState<GoalType>()

  useEffect(() => {
    if (Goals?.length > 0) {
      setLastGoal(Goals?.[0])
      setActualGoal(getGoalOfActualMonth(Goals))
    }
  }, [Goals])

  useEffect(() => {
    if (!actualGoal && lastGoal) {
      const today = new Date()
      const parseMonth = `${today.getFullYear()}${('0' + (today.getMonth() + 1)).slice(-2)}`

      // CreateGoal
      const objToCreate = {
        AuthorUUID: lastGoal.AuthorUUID,
        Month: parseMonth,
        TotalKG: lastGoal.TotalKG,
        TotalLT: lastGoal.TotalLT,
      }
      execWithCallback(objToCreate)(async (response) => {
        setActualGoal(response)
      })
    }
  }, [actualGoal])

  return { lastGoal, loading: createLoading || isLoading, actualGoal }
}

export default useCftGoal
