import clsx from 'clsx'
import { LayoutGroup, motion } from 'framer-motion'
import { Dispatch, SetStateAction } from 'react'

import { SearchSource } from '@/ts/types/general.types'

type Props = {
  /**
   * Query to search
   */
  currentSource?: string
  /**
   * Sources where to search
   */
  sources?: SearchSource[]
  onSelect: Dispatch<SetStateAction<string>>
}

const SourceSelector = ({ sources, currentSource, onSelect }: Props): JSX.Element => {
  return (
    <LayoutGroup>
      <div className="px-2 sm:px-4 py-2">
        <div className="flex justify-between items-center border p-1 rounded-full overflow-auto">
          <div className="relative">
            <button onClick={() => onSelect(null)} className="px-4 py-2 z-20 relative">
              <span
                className={clsx(
                  'text-xs sm:text-sm  leading-none block',
                  !currentSource ? 'text-white' : 'text-gray-700'
                )}
              >
                Todos
              </span>
            </button>
            {!currentSource ? (
              <motion.div
                layoutId="sourceActiveIndicator"
                className="absolute top-0 left-0 w-full h-full rounded-full bg-accent-1  z-10"
              />
            ) : null}
          </div>

          {sources.map((source) => (
            <div key={source.key} className="relative">
              <button onClick={() => onSelect(source.key)} className="px-4 py-2 z-20 relative">
                <span
                  className={clsx(
                    'text-xs sm:text-sm  leading-none block',
                    source.key === currentSource ? 'text-white' : 'text-gray-700'
                  )}
                >
                  {source.name()}
                </span>
              </button>
              {source.key === currentSource ? (
                <motion.div
                  layoutId="sourceActiveIndicator"
                  className="absolute top-0 left-0 w-full h-full rounded-full bg-accent-1  z-10"
                />
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </LayoutGroup>
  )
}

export default SourceSelector
