import Image from 'next/image'
import { BsImageFill } from 'react-icons/bs'
import { FiChevronRight } from 'react-icons/fi'

import { Post } from '@/ts/types/post.types'

import CustomLink from '../../CustomLink'
import { useSearchbar } from '../../Layout/RightBar/SearchBar'

type Props = {
  /**
   * company to show
   */
  post?: Post
}

const SearchResultItemPost = ({ post }: Props): JSX.Element => {
  const { close } = useSearchbar()

  return (
    <CustomLink href={`/p/${post.UUID}`} onClick={close} className="w-full block">
      <div className="w-full bg-white hover:bg-gray-100 p-3 overflow-hidden-z">
        <div className="flex items-center w-full space-x-3 box-border">
          <div>
            {post.Metadata?.Type === 'image' && post.Metadata.Data?.Image?.Thumbnail ? (
              <div className="rounded-full relative overflow-hidden-z w-12 sm:w-16 h-12 sm:h-16 bg-gray-200">
                <Image src={post.Metadata.Data.Image.Thumbnail} className="object-cover" fill alt="post-thumbnail" />
              </div>
            ) : (
              <div className="flex items-center justify-center w-12 sm:w-16 h-12 sm:h-16 rounded-full bg-gray-200">
                <BsImageFill className="text-3xl opacity-75 text-gray-400" />
              </div>
            )}
          </div>
          <div className="flex-1 overflow-hidden-z space-y-1">
            <span className="block text-xs font-semibold text-blue-500 group-hover:text-white leading-none uppercase m-0">
              Publicación
            </span>
            <h4 className="block text-gray-800 font-semibold group-hover:text-white leading-none w-full text-sm sm:text-base truncate">
              {post.Content}
            </h4>
            <p className="block text-sm font-medium leading-tight text-gray-700 group-hover:text-white truncate">
              {post.Included?.Author?.Name}
            </p>
          </div>
          <div>
            <FiChevronRight />
          </div>
        </div>
      </div>
    </CustomLink>
  )
}

export default SearchResultItemPost
