export const BadgeModalBackground = () => {
  return (
    <svg viewBox="0 0 298 323" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_29_1316)">
        <rect width="298" height="323" fill="#A5F4F7" />
        <mask
          id="mask0_29_1316"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="75"
          width="299"
          height="323"
        >
          <path
            d="M28.4293 75C12.7284 75 0 87.728 0 103.429V369.208C0 384.909 12.7284 397.637 28.4293 397.637H269.995C285.696 397.637 298.424 384.909 298.424 369.208V103.429C298.424 87.728 285.696 75 269.995 75H28.4293Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_29_1316)">
          <path d="M312.224 65.1675H-28.5791V419.948H312.224V65.1675Z" fill="#00CCFF" />
          <path
            d="M9.28418 407.751L95.1844 351.587C94.0754 336.614 109.198 318.76 117.871 317.025L127.412 284.211L138.832 283.921L143.471 384.624L126.698 394.282L120.09 407.751H9.28418Z"
            fill="#FFDA33"
          />
          <path
            d="M156.579 304.229C158.104 338.876 163.776 368.645 140.145 379.624V310.666L156.579 304.229Z"
            fill="#FFDA33"
          />
          <path d="M153.442 304.307L165.405 300.043V361.041L157.924 368.635L153.442 304.307Z" fill="#336666" />
          <path d="M162.762 269.674H135.654V386.563H162.762V269.674Z" fill="#33CC66" />
        </g>
        <mask
          id="mask1_29_1316"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="135"
          y="269"
          width="28"
          height="118"
        >
          <path d="M162.762 269.675H135.653V386.563H162.762V269.675Z" fill="white" />
        </mask>
        <g mask="url(#mask1_29_1316)">
          <path d="M145.655 308.136H130.406V357.609H145.655V308.136Z" fill="#FF6B1C" />
        </g>
        <mask
          id="mask2_29_1316"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="75"
          width="299"
          height="181"
        >
          <path
            d="M28.4293 75C12.7284 75 0 87.7273 0 103.429V106.186L149.212 255.398L298.423 106.186V103.429C298.423 87.7273 285.696 75 269.995 75H28.4299H28.4293Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_29_1316)">
          <path d="M335.73 19.2371H-21.615V256.022H335.73V19.2371Z" fill="#A5F4F7" />
        </g>
        <mask
          id="mask3_29_1316"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="75"
          width="299"
          height="181"
        >
          <path
            d="M28.4293 75C12.7284 75 0 87.7273 0 103.429V106.186L149.212 255.398L298.423 106.186V103.429C298.423 87.7273 285.696 75 269.995 75H28.4293Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_29_1316)">
          <path
            d="M298.357 113.034C298.018 107.576 293.489 103.187 288.025 103.006C286.327 102.95 284.716 103.289 283.274 103.938C280.699 105.096 277.811 105.177 275.183 104.209C273.809 103.493 272.27 103.06 270.645 103.006C270.492 103.002 270.342 103.016 270.189 103.018C270.037 103.016 269.887 103.001 269.734 103.006C268.108 103.06 266.569 103.493 265.194 104.209C262.566 105.177 259.679 105.095 257.103 103.937C255.662 103.289 254.051 102.95 252.355 103.006C246.89 103.187 242.36 107.576 242.021 113.033C241.635 119.261 246.572 124.433 252.716 124.433C254.352 124.433 255.902 124.066 257.288 123.411C259.66 122.289 262.403 122.178 264.853 123.063C266.404 123.935 268.191 124.433 270.096 124.433C270.128 124.433 270.157 124.428 270.189 124.428C270.22 124.428 270.251 124.433 270.282 124.433C272.187 124.433 273.974 123.935 275.524 123.063C277.975 122.178 280.717 122.289 283.089 123.411C284.475 124.066 286.026 124.433 287.661 124.433C293.806 124.433 298.742 119.262 298.357 113.034Z"
            fill="white"
          />
          <path
            d="M64.3564 123.034C64.0176 117.577 59.4883 113.187 54.024 113.006C52.3275 112.95 50.7159 113.289 49.2742 113.938C46.698 115.096 43.8103 115.177 41.1828 114.209C39.8087 113.493 38.2696 113.06 36.6448 113.006C36.4911 113.001 36.3411 113.016 36.1887 113.017C36.0363 113.016 35.8869 113.001 35.7332 113.006C34.1071 113.06 32.5681 113.493 31.1933 114.21C28.5665 115.177 25.6782 115.095 23.1026 113.937C21.6615 113.289 20.0504 112.95 18.3546 113.006C12.8896 113.187 8.35916 117.576 8.02102 123.033C7.63435 129.261 12.5716 134.433 18.7159 134.433C20.3521 134.433 21.9013 134.066 23.2878 133.41C25.6592 132.289 28.4026 132.178 30.8536 133.063C32.4035 133.934 34.1906 134.433 36.0958 134.433C36.1273 134.433 36.1572 134.428 36.1881 134.428C36.2196 134.428 36.2501 134.433 36.2816 134.433C38.1862 134.433 39.9738 133.934 41.5238 133.063C43.9748 132.178 46.7169 132.289 49.0883 133.41C50.4749 134.066 52.0255 134.433 53.6609 134.433C59.8054 134.433 64.7416 129.262 64.3564 123.034Z"
            fill="white"
          />
        </g>
        <path
          d="M115.91 222.106V244.467C115.91 248.826 117.555 253.025 120.515 256.225L135.66 272.595H162.763L177.908 256.225C180.868 253.025 182.513 248.826 182.513 244.467V222.106H115.91Z"
          fill="#33CC66"
        />
        <mask
          id="mask4_29_1316"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="115"
          y="222"
          width="68"
          height="51"
        >
          <path
            d="M115.909 222.106V244.468C115.909 248.827 117.554 253.026 120.514 256.226L135.659 272.596H162.762L177.907 256.226C180.868 253.026 182.512 248.827 182.512 244.468V222.106H115.909Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_29_1316)">
          <path d="M131.477 214.858H129.507V241.035H131.477V214.858Z" fill="#FFED99" />
          <path d="M143.956 214.858H141.987V241.035H143.956V214.858Z" fill="#FFED99" />
          <path d="M156.435 214.858H154.465V241.035H156.435V214.858Z" fill="#FFED99" />
          <path d="M168.915 214.858H166.946V241.035H168.915V214.858Z" fill="#FFED99" />
          <path
            d="M113.751 244.922C120.842 244.922 120.842 248.537 127.933 248.537C135.024 248.537 135.024 244.922 142.115 244.922C149.206 244.922 149.206 248.537 156.296 248.537C163.386 248.537 163.386 244.922 170.476 244.922C177.566 244.922 177.566 248.537 184.656 248.537V238.216H113.751V244.922Z"
            fill="#FFED99"
          />
        </g>
        <path d="M144.734 301.944V313.855L166.278 313.909V300.05L144.734 301.944Z" fill="#FFDA33" />
        <path d="M142.421 316.613L142.511 328.769L166.271 330.539V315.325L142.421 316.613Z" fill="#FFDA33" />
        <path
          d="M32.3572 26.034C32.0184 20.5769 27.4891 16.1865 22.0248 16.0059C20.3276 15.9502 18.7174 16.2891 17.275 16.9378C14.6994 18.0959 11.8111 18.1773 9.18364 17.2089C7.80949 16.4933 6.27042 16.0598 4.64495 16.0059C4.49132 16.0014 4.34247 16.0158 4.1901 16.017C4.03789 16.0158 3.88826 16.0007 3.73463 16.0059C2.10837 16.0598 0.56949 16.4932 -0.805286 17.2095C-3.43273 18.1773 -6.32103 18.0953 -8.89663 16.9372C-10.3377 16.2891 -11.9488 15.9502 -13.6452 16.0059C-19.1096 16.1865 -23.64 20.5755 -23.9788 26.0333C-24.3648 32.2612 -19.4272 37.4333 -13.2827 37.4333C-11.6466 37.4333 -10.0973 37.0663 -8.71075 36.4109C-6.33936 35.2891 -3.59645 35.1781 -1.14627 36.0632C0.40435 36.9344 2.19141 37.4333 4.09593 37.4333C4.12744 37.4333 4.15797 37.4281 4.18948 37.4281C4.221 37.4281 4.25091 37.4333 4.28242 37.4333C6.18694 37.4333 7.97509 36.9344 9.52524 36.0632C11.9754 35.1781 14.7183 35.2891 17.0897 36.4109C18.4763 37.0663 20.0261 37.4333 21.6616 37.4333C27.806 37.4333 32.7426 32.2618 32.3572 26.034Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29_1316">
          <rect width="298" height="323" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
