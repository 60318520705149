import Link from 'next/link'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import Spinner from '@/components/base/Spinner'
import useRequest2 from '@/hooks/useRequest2'
import { findNextActivities } from '@/services/eventsService'

import EventSlimItem from './EventSlimItem'

export const NextEvents = () => {
  const [eventsData, loading] = useRequest2({
    requestKey: '/events/next-events',
    request: findNextActivities,
  })

  if (eventsData?.length < 1) {
    return
  }

  return (
    <section className="flex flex-col items-start">
      {loading ? (
        <Spinner size={50} className="text-primary self-center" />
      ) : (
        <>
          <CustomText className="text-black mb-2" size={TextSize.SubTitle2} weight={FontWeight.SemiBold}>
            Próximos eventos
          </CustomText>
          <div className="bg-white sunet-shadow rounded-xl flex flex-col gap-4 items-center pb-2 w-full">
            {eventsData?.length
              ? eventsData.slice(0, 2).map((item) => <EventSlimItem key={item.UUID} event={item} />)
              : null}

            <Link className="underline font-semibold text-black" href="/events">
              Ver todos los eventos
            </Link>
          </div>
        </>
      )}
    </section>
  )
}
