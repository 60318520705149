import Link from 'next/link'
import { ReactNode } from 'react'

import Card from '@/components/base/Card'
import Container from '@/components/base/Container'
import CustomText, { TextSize } from '@/components/base/CustomText'
import { useAuthStore } from '@/dataStores/auth'

const LinkWrapper = ({ children, href }: { children: ReactNode; href: string }) => (
  <Link href={href} className="text-brand" target="_blank" rel="noopener noreferrer">
    {children}
  </Link>
)

const InactiveMessage = (): JSX.Element => {
  const [user] = useAuthStore((state) => [state.user])

  return (
    <Container maxWidth={800} className="mx-auto">
      <Card className="mt-10">
        <div>
          <CustomText size={TextSize.SubTitle1}>
            <span>Acceso limitado</span>
          </CustomText>
          <CustomText className="leading-tight">
            <span>
              <span>Tu cuenta ha sido bloqueada por incumplir con los</span>{' '}
              <LinkWrapper href="/static/documents/terms.pdf">términos de uso</LinkWrapper>
              <span>. Por favor, ponte en contacto con</span>{' '}
              <a className="text-brand" href="mailto:ayuda@sunet.cl">
                ayuda@sunet.cl
              </a>{' '}
              <span>para resolver la situación.</span>
            </span>
          </CustomText>

          {user?.DeactivateReason ? (
            <div>
              <CustomText className="text-gray-700 leading-none font-bold mt-5 mb-1">
                <span>Razón</span>:
              </CustomText>
              <CustomText className="p-3 bg-gray-200 bg-opacity-50 rounded-lg text-gray-700 leading-tight ">
                {user.DeactivateReason}
              </CustomText>
            </div>
          ) : null}
        </div>
      </Card>
    </Container>
  )
}

export default InactiveMessage
