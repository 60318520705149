import { createContext, Dispatch, SetStateAction, useContext } from 'react'

interface LayoutContext {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}

export const LayoutContext = createContext<LayoutContext>({
  loading: false,
  setLoading: () => {
    return false
  },
})
export const useLayout = () => useContext(LayoutContext)
7
