import { searchUsersByQuery } from '@/services/authenticationServices'
import { searchChallengesByQuery } from '@/services/challengeService'
import { searchCompaniesByQuery } from '@/services/companiesService'
import { searchInitiativesByQuery } from '@/services/initiativesService'
import { searchPostsByQuery } from '@/services/postsService'

import SearchResultItemChallenge from '../../SearchBar/Items/SearchResultItemChallenge'
import SearchResultItemCompany from '../../SearchBar/Items/SearchResultItemCompany'
import SearchResultItemInitiative from '../../SearchBar/Items/SearchResultItemInitiative'
import SearchResultItemPost from '../../SearchBar/Items/SearchResultItemPost'
import SearchResultItemUser from '../../SearchBar/Items/SearchResultItemUser'
import SearchResults from '../../SearchBar/SearchResults'
import SearchBar from './SearchBar'

const searchSources = [
  {
    key: 'companies',
    name: () => `Organizaciones`,
    action: [searchCompaniesByQuery],
    renderItem: function Item(item) {
      return <SearchResultItemCompany company={item} />
    },
  },
  {
    key: 'users',
    name: () => `Perfiles`,
    action: [searchUsersByQuery],
    renderItem: function Item(item) {
      return <SearchResultItemUser user={item} />
    },
  },
  {
    key: 'posts',
    name: () => `Publicaciones`,
    action: [searchPostsByQuery],
    renderItem: function Item(item) {
      return <SearchResultItemPost post={item} />
    },
  },
  {
    key: 'challenges',
    name: () => `Desafíos`,
    action: [searchChallengesByQuery],
    renderItem: function Item(item) {
      return <SearchResultItemChallenge challenge={item} />
    },
  },
  {
    key: 'initiatives',
    name: () => `Iniciativas`,
    action: [searchInitiativesByQuery],
    renderItem: function Item(item) {
      return <SearchResultItemInitiative initiative={item} />
    },
  },
]

const SearchBarWithOptions = ({
  showButton = false,
  customOpen = undefined,
  customSetOpen = undefined,
}): JSX.Element => {
  return (
    <SearchBar showButton={showButton} customOpen={customOpen} customSetOpen={customSetOpen}>
      {({ query }) => <SearchResults query={query} sources={searchSources} />}
    </SearchBar>
  )
}

export default SearchBarWithOptions
