import React from 'react'
import ContentLoader from 'react-content-loader'

type Props = {
  width?: string | number
  height?: string | number
  radius?: string | number
  className?: string
  uniqueKey?: string
  style?: React.CSSProperties
}

const SkeletonBlock = ({
  width = '100%',
  height = '20px',
  radius = 5,
  className,
  style,
  uniqueKey = 'SkeletonBlock',
}: Props): JSX.Element => {
  return (
    <ContentLoader
      width={width}
      height={height}
      foregroundOpacity={0.9}
      style={{ display: 'block', ...style }}
      className={className}
      uniqueKey={uniqueKey}
      viewBox={`0 0 ${width} ${height}`}
    >
      <rect x="0" y="0" rx={radius} ry={radius} width="100%" height="100%" />
    </ContentLoader>
  )
}

export default SkeletonBlock
