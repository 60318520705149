import { Plural } from '@lingui/macro'
import clsx from 'clsx'

import CustomText, { TextSize } from '@/components/base/CustomText'

type Props<T> = {
  /**
   * Query to search
   */
  results?: T[]
  /**
   * Sources where to search
   */
  query?: string
}

const Counter = <T,>({ results, query }: Props<T>): JSX.Element => {
  return (
    <div className="bg-white px-2 sm:px-4 h-10 border-t border-gray-200 flex items-center">
      <CustomText
        className={clsx('m-0  text-gray-600 transition duration-300', results && query ? 'opacity-100' : 'opacity-0')}
        size={TextSize.Caption}
      >
        {results?.length} resultado
      </CustomText>
    </div>
  )
}

export default Counter

/*        <Plural
          value={results?.length || 0}
          one="# resultado"
          other="# resultados"
        />*/
