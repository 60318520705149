import useSWR from 'swr'

import { challengeURLEndpoint, findUserChallengeCount } from '@/services/challengeService'

type Props = {
  authorUUID: string
}

const useUserChallengeCount = ({ authorUUID }: Props): number => {
  const { data: count } = useSWR<number>(
    authorUUID
      ? {
          page: challengeURLEndpoint,
          authorUUID: authorUUID,
        }
      : null,
    findUserChallengeCount,
    { revalidateOnFocus: false }
  )

  return count || 0
}

export default useUserChallengeCount
