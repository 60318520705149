import { startOfDay } from 'date-fns'

import { EventsType, TicketType } from '@/ts/types/events.types'

import axios, { getAxiosByAccessToken } from '../utils/axios'

export const findAllActivitiesNotFinished = async (accessToken: string): Promise<EventsType[]> => {
  const end = startOfDay(new Date()).toISOString()

  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance
    .get(`/activities?EndsAt~ge=${end}&order=StartsAt&include=Company`)
    .then((res: { data: { Data: EventsType[] } }) => res.data.Data)
}

export const findNextActivities = async (accessToken: string): Promise<EventsType[]> => {
  const end = startOfDay(new Date()).toISOString()

  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance
    .get(`/activities?EndsAt~ge=${end}&order=StartsAt&include=Company&WithoutDeleted=true`)
    .then((res: { data: { Data: EventsType[] } }) => res.data.Data)
}

export const findActivityByUUID = async (activityUUID: string, accessToken: string): Promise<EventsType> => {
  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance.get(`/activities/${activityUUID}`).then((res: { data: { Data: EventsType } }) => res.data.Data)
}

/* TICKET */

export const findTicketsByUserUUIDAndActivityUUID = async (params: unknown[]): Promise<TicketType[]> => {
  const userUUID = params[0]
  const activityUUID = params[1]
  return axios
    .get(`/activities/tickets?UserUUID=${userUUID}&ActivityUUID=${activityUUID}`)
    .then((res: { data: { Data: TicketType[] } }) => res.data.Data)
}

export const createTicketByUserUUIDAndActivityUUID = async (
  userUUID: string,
  activityUUID: string
): Promise<TicketType> => {
  return axios
    .post(`/activities/tickets`, {
      UserUUID: userUUID,
      ActivityUUID: activityUUID,
    })
    .then((res: { data: { Data: TicketType } }) => res.data.Data)
}

export const deleteTicketByUUID = async (ticketUUID: string): Promise<void> => {
  return axios.delete(`/activities/tickets/${ticketUUID}`)
}

export const markTicketAttendanceByTicketUUID = async (ticketUUID: string): Promise<TicketType> => {
  return axios
    .post(`/activities/tickets/${ticketUUID}/mark-attendance`)
    .then((res: { data: { Data: TicketType } }) => res.data.Data)
}

export const shareActivityWithFollowers = async (
  activityUUID: string,
  AuthorUUID: string,
  UserUUIDs: string[]
): Promise<EventsType> => {
  return axios
    .post(`/activities/${activityUUID}/share`, {
      AuthorUUID: AuthorUUID,
      ActivityUUID: activityUUID,
      UserUUIDs: UserUUIDs,
    })
    .then((res: { data: { Data: EventsType } }) => res.data.Data)
}
