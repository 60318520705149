import Image from 'next/image'
import { BsImageFill } from 'react-icons/bs'
import { FiChevronRight } from 'react-icons/fi'

import { SunetUser } from '@/ts/types/user.types'

import CustomLink from '../../CustomLink'
import { useSearchbar } from '../../Layout/RightBar/SearchBar'

type Props = {
  /**
   * user to show
   */
  user?: SunetUser
}

const SearchResultItemUser = ({ user }: Props): JSX.Element => {
  const { close } = useSearchbar()

  return (
    <CustomLink href={`/profile/u/${user.UUID}`} onClick={close} className="w-full block">
      <div className="w-full bg-white hover:bg-gray-100 p-3 overflow-hidden-z">
        <div className="flex items-center w-full space-x-3 box-border">
          <div>
            {user.Photo ? (
              <div className="rounded-full relative overflow-hidden-z w-12 sm:w-16 h-12 sm:h-16 bg-gray-200">
                <Image src={user.Photo?.Thumbnail} fill className="object-cover rounded-full" alt="user-thumbnail" />
              </div>
            ) : (
              <div className="flex items-center justify-center w-12 sm:w-16 h-12 sm:h-16 rounded-full bg-gray-200">
                <BsImageFill className="text-3xl opacity-75 text-gray-400" />
              </div>
            )}
          </div>
          <div className="flex-1 overflow-hidden-z space-y-1">
            <span className="block text-xs font-semibold text-red-500 group-hover:text-white leading-none uppercase m-0">
              Usuario
            </span>
            <h4 className="block text-gray-800 font-semibold group-hover:text-white leading-none text-sm sm:text-base truncate sm:my-1">
              {user.FirstName} {user.LastName}
              {user?.IsCertified ? (
                <Image
                  src={user.Photo?.Thumbnail}
                  height={14}
                  width={14}
                  alt="/static/images/certified.svg"
                  className="inline-block ml-2"
                />
              ) : null}
            </h4>
            <p className="block text-sm font-medium leading-tight text-gray-700 group-hover:text-white truncate">
              {user.Biography}
            </p>
          </div>
          <div>
            <FiChevronRight />
          </div>
        </div>
      </div>
    </CustomLink>
  )
}

export default SearchResultItemUser
