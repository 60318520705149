import Image from 'next/image'
import { FiChevronRight } from 'react-icons/fi'

import { InitiativeType } from '@/ts/types/initiatives.types'
import { getLocaleText } from '@/utils/i18n'

import CustomLink from '../../CustomLink'
import { useSearchbar } from '../../Layout/RightBar/SearchBar'

type Props = {
  /**
   * company to show
   */
  initiative?: InitiativeType
}

const SearchResultItemInitiative = ({ initiative }: Props): JSX.Element => {
  const { close } = useSearchbar()

  return (
    <CustomLink href={`/initiatives/${initiative.UUID}`} onClick={close} className="w-full block">
      <div className="w-full bg-white hover:bg-gray-100 p-3 overflow-hidden-z">
        <div className="flex items-center w-full space-x-3 box-border">
          <div>
            <div className="rounded-full relative overflow-hidden-z w-12 sm:w-16 h-12 sm:h-16 bg-gray-200">
              <Image src={initiative.Banner.URL} className="object-cover" fill alt="post-thumbnail" />
            </div>
          </div>
          <div className="flex-1 w-3/4 overflow-hidden-z space-y-1">
            <span className="block text-xs font-semibold text-blue-500 group-hover:text-white leading-none uppercase m-0">
              Iniciativa
            </span>
            <h4 className="block text-gray-800 font-semibold group-hover:text-white leading-none w-full text-sm sm:text-base truncate">
              {getLocaleText(initiative.Title)}
            </h4>
            <p className="block text-sm font-medium leading-tight text-gray-700 group-hover:text-white truncate">
              {getLocaleText(initiative.Included?.Author?.Name)}
            </p>
          </div>
          <div>
            <FiChevronRight />
          </div>
        </div>
      </div>
    </CustomLink>
  )
}

export default SearchResultItemInitiative
