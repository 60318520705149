import { getAuthorByUUID } from '@/services/authorService'

import useRequest2 from './useRequest2'

type Props = {
  AuthorUUID: string
  key?: string
}

export const useAuthorByUUID = ({ AuthorUUID, key = '' }: Props) => {
  const [authorData, loading, errors] = useRequest2({
    requestKey: `/author${key}`,
    params: [AuthorUUID],
    request: (UUID) => getAuthorByUUID({ authorUUID: UUID }),
  })

  return {
    loading,
    authorData,
    errors,
  }
}
