type Props = {
  width?: number
  height?: number
}

const SunetIso = ({ height, width }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 158 201" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2886_3643)">
        <path
          d="M100.935 112.163C100.935 103.19 88.8485 83.8072 82.6206 74.4084C80.9058 71.8202 77.0938 71.8202 75.3791 74.4084C69.1511 83.8072 57.0649 103.196 57.0649 112.163C57.0649 124.245 66.8882 134.045 78.9998 134.045C91.1115 134.045 100.935 124.245 100.935 112.163Z"
          fill="#1AC5E9"
        />
        <path
          d="M72.141 150.464C72.141 148.556 70.8979 146.852 69.062 146.318C54.2221 142.013 43.3407 128.334 43.3407 112.157C43.3407 106.898 44.8068 98.389 56.2173 79.1208C61.9162 69.493 67.5513 61.5186 67.7871 61.1816L71.3441 56.1579C71.8605 55.4266 72.141 54.5554 72.141 53.6587V4.34351C72.141 0.629769 67.7616 -1.36064 64.9505 1.06855L1.49802 55.9925C0.548213 56.8129 0 58.0084 0 59.2675V149.898C0 151.227 0.605584 152.48 1.65101 153.3L52.826 193.611C57.0205 196.911 61.6867 199.315 66.5632 200.809C69.3362 201.662 72.1346 199.544 72.1346 196.651V150.464H72.141Z"
          fill="#38C16F"
        />
        <path
          d="M85.8589 4.34339V53.665C85.8589 54.5616 86.1394 55.4328 86.6557 56.1641L90.2127 61.1878C90.4486 61.5249 96.0837 69.4992 101.783 79.127C113.193 98.3952 114.659 106.904 114.659 112.163C114.659 128.334 103.778 142.019 88.9378 146.324C87.1019 146.858 85.8589 148.563 85.8589 150.47V196.657C85.8589 199.55 88.6573 201.668 91.4303 200.816C96.3068 199.321 100.973 196.918 105.167 193.617L156.342 153.306C157.388 152.486 157.993 151.233 157.993 149.904V59.261C157.993 58.0083 157.445 56.8127 156.495 55.986L93.0494 1.06843C90.2382 -1.36713 85.8589 0.629647 85.8589 4.34339Z"
          fill="#FED444"
        />
      </g>
      <defs>
        <clipPath id="clip0_2886_3643">
          <rect width="158" height="201" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SunetIso
