import { CountNotification, NotificationType } from '@/ts/types/notification.types'

import axios, { getAxiosByAccessToken } from '../utils/axios'

export const findReadNotificationsCountByRecipientUUID = (params: unknown[]): Promise<CountNotification> => {
  const recipientUUID = params[0]
  return axios
    .get(`/notifications?RecipientUUID=${recipientUUID}&Read=false&offset=0&limit=0`)
    .then((res: { data: { Data: CountNotification } }) => res.data.Data)
}

export const findAllNotificationsByRecipientUUID = async (
  params: unknown[],
  accessToken: string
): Promise<CountNotification> => {
  const recipientUUID = params[0]

  const axiosInstance = accessToken ? getAxiosByAccessToken(accessToken) : axios

  return axiosInstance
    .get(`/notifications?RecipientUUID=${recipientUUID}&include=Subject&order=-CreatedAt&offset=0&limit=30`)
    .then((res: { data: { Data: CountNotification } }) => res.data.Data)
}

export const findAllNotificationsByRecipientUUIDAndNotRead = async (params: unknown[]): Promise<NotificationType[]> => {
  const recipientUUID = params[0]
  return axios
    .get(`/notifications?RecipientUUID=${recipientUUID}&include=Subject&order=-CreatedAt&offset=0&Read=false&limit=30`)
    .then((res: { data: { Data: NotificationType[] } }) => res.data.Data)
}

export const readNotificationsByUUIDs = async (uuids, RecipientUUID): Promise<void> => {
  return axios.post('/notifications/read', { NotificationUUIDs: uuids, RecipientUUID })
}

export const readAllNotifications = async (RecipientUUID): Promise<void> => {
  return axios.post('/notifications/read-all', { RecipientUUID })
}
