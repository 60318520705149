import { useRouter } from 'next/router'
import { FaCheckCircle } from 'react-icons/fa'

import Card from '@/components/base/Card'
import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import { useAuthStore } from '@/dataStores/auth'
import useSunetAuthors from '@/hooks/useSunetAuthors'

import Avatar from '../Avatar'

export const SelectAuthorModal = ({ onSelect }) => {
  const { authors, author, companyAuthors } = useSunetAuthors()

  const { pathname } = useRouter()

  const companyOnly = pathname === '/challenges/create' || pathname == '/initiatives/create'

  const authorList = companyOnly ? companyAuthors : authors

  return (
    <Card className="my-10 overflow-hidden-z !p-8">
      <CustomText weight={FontWeight.SemiBold} size={TextSize.Heading2}>
        Usar otro usuario
      </CustomText>
      <CustomText weight={FontWeight.Normal} size={TextSize.SubTitle2}>
        Aquí podrás seleccionar otro usuario para comentar, dar me gusta y crear publicaciones, iniciativas, desafíos o
        grupos.
      </CustomText>
      <div className="flex flex-col gap-2 p-4">
        {authorList.map((item) => (
          <AuthorItem key={item.UUID} onSelect={onSelect} selected={item.UUID === author.UUID} authorItem={item} />
        ))}
      </div>
    </Card>
  )
}

const AuthorItem = ({ authorItem, selected, onSelect }) => {
  const { setAuthorUUID } = useAuthStore((state) => ({ setAuthorUUID: state.setAuthorUUID }))
  return (
    <div
      className="flex items-center justify-between p-4 hover:bg-gray-200 rounded-xl cursor-pointer"
      onClick={() => {
        setAuthorUUID(authorItem.UUID)
        onSelect()
      }}
    >
      <div className="flex gap-4">
        <Avatar
          text={authorItem?.Name}
          image={authorItem?.Photo?.Thumbnail}
          className="w-10 h-10 text-white !rounded-full ring-2 ring-gray-3"
          showBorder={false}
        />
        <div>
          <CustomText weight={FontWeight.SemiBold}>{authorItem?.Name}</CustomText>
          {authorItem.Type === 'company' && <CustomText>Empresa</CustomText>}
        </div>
      </div>
      {selected && <FaCheckCircle size={24} className="text-primary" />}
    </div>
  )
}
