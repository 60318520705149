import { getCookie } from 'cookies-next'

import { CHAT_URL } from '@/config'
import useSunetAuthors from '@/hooks/useSunetAuthors'

import Card from '../base/Card'
import Modal from '../base/CustomModal'

export default function MessageModal({ participantUUID, open, setOpen, productUUID, channelUUID }) {
  const { author } = useSunetAuthors()
  const token = getCookie('sunet-token')
  let url = `${CHAT_URL}/by-participants?senderUUID=${author?.UUID}&participantUUID=${participantUUID}&token=${token}`

  if (productUUID) {
    url += `&productUUID=${productUUID}`
  }
  if (channelUUID) {
    url += `&channelUUID=${channelUUID}`
  }

  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)} maxWidth={650} animate showCloseButton>
        <Card className="overflow-hidden-z">
          {open ? <iframe src={url} width="100%" height="600" frameBorder="0" /> : null}
        </Card>
      </Modal>
    </div>
  )
}
