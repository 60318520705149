import { ImpactSummarySection } from './ImpactSummarySection'
import { NextEvents } from './NextEvents'
import SearchBarWithOptions from './SearchBarWithOptions'
import CftProgress from '@/components/shared/Layout/RightBar/CftProgress'

const RightBarContent = ({}): JSX.Element => {
  return (
    <div className="py-4">
      <div className="self-center space-y-3">
        <SearchBarWithOptions showButton={true} />

        <NextEvents />

        <ImpactSummarySection />

        <CftProgress />
      </div>
    </div>
  )
}

export default RightBarContent
