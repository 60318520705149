import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { CSSProperties, ReactNode, useState } from 'react'
import Sticky from 'react-stickynode'

import LoadingWrapper from '@/components/base/LoadingWrapper'
import { useAuthStore } from '@/dataStores/auth'

import { ConnectedFollowees } from './ConnectedFollowees/ConnectedFollowees'
import { LayoutContext } from './context'
import InactiveMessage from './InactiveMessage'
import LeftBarContent from './LeftBar/LeftBarContent'
import RightBarContent from './RightBar/RightBarContent'
import SEO, { SEOProps } from './SEO'
import useWindowResize from '@/hooks/useWindowResize'
import { BadgeModal } from './BadgeModal'
const MobileNavigation = dynamic(() => import('./LeftBar/MobileNavbar'), {
  ssr: false,
})

const MobileHeader = dynamic(() => import('./LeftBar/MobileHeader'), {
  ssr: false,
})

const MobileSlideMenu = dynamic(() => import('./LeftBar/MobileSlideMenu'), {
  ssr: false,
})
type Props = {
  /**
   * ClassNames
   */
  SEOProps?: SEOProps
  /**
   * Content
   */
  children?: ReactNode | string
  /**
   * Style
   */
  style?: CSSProperties
  /**
   * ClassNames
   */
  className?: string
  /**
   * Shows left sidebar
   */
  showLeftBar?: boolean
  /**
   *  Shows right sidebar
   */
  showRightBar?: boolean

  modal?: boolean
}

const Layout = ({
  SEOProps,
  children,
  className,
  style,
  showLeftBar = true,
  showRightBar = true,
  modal = false,
}: Props): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const [user] = useAuthStore((state) => [state.user])
  const [menuOpen, setMenuOpen] = useState(false)
  const { width } = useWindowResize()

  const router = useRouter()

  const isMessages = router.pathname === '/messages'
  const isFootprint = router.pathname === '/cft/footprint'
  const isPostModal = router.pathname === '/p/[postUUID]'

  if (isFootprint && width < 640) {
    return (
      <LayoutContext.Provider value={{ loading, setLoading }}>
        <SEO {...SEOProps} />
        <main className="!p-0">{children}</main>
      </LayoutContext.Provider>
    )
  }

  // const getMainClassName =

  return (
    <LayoutContext.Provider value={{ loading, setLoading }}>
      <SEO {...SEOProps} />
      <main
        className={`${isMessages ? 'pt-[3.2rem]' : isPostModal ? 'pt-0' : 'pt-16'} md:pt-0 ${
          isFootprint ? '!p-0 !mt-0' : ''
        }`}
      >
        <LoadingWrapper loading={loading} style={style} className={clsx(className)}>
          {user && !user.IsActive ? (
            <InactiveMessage />
          ) : (
            <div className="relative w-full h-full flex flex-row lg:justify-center xl:justify-start">
              {showLeftBar ? (
                <div>
                  <Sticky enabled={true} className="md:w-16 xl:w-80">
                    <div
                      style={{ maxWidth: 600, minHeight: '100vh', zIndex: 49 }}
                      className="hidden md:block w-full px-2 xl:pl-0 xl:pr-2 border-r border-gray-3 relative bg-white"
                    >
                      <div className="mr-auto w-full h-full">
                        <LeftBarContent />
                      </div>
                    </div>
                  </Sticky>

                  {!isFootprint && <MobileHeader />}
                  <MobileSlideMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                  {!isFootprint && <MobileNavigation setMenuOpen={setMenuOpen} />}
                  <ConnectedFollowees />
                  <BadgeModal />
                </div>
              ) : null}

              <div className="flex flex-row mx-auto lg:m-0 flex-1 min-w-0">
                <div
                  className={`flex-1 min-w-0 ${!isFootprint ? 'pt-4' : 'pt-0'}  ${
                    !isMessages ? 'pb-32' : 'pb-[4.2rem]'
                  } `}
                  style={{
                    backgroundColor: modal ? 'transparent' : '#F1F4F6',
                  }}
                >
                  {children}
                </div>

                {showRightBar ? (
                  <Sticky enabled={true} className="hidden lg:block w-80">
                    <div
                      className="w-full lg:px-2  mr-auto border-l border-gray-3 bg-white"
                      style={{ maxWidth: 640, minHeight: '100vh' }}
                    >
                      <RightBarContent />
                    </div>
                  </Sticky>
                ) : null}
              </div>
            </div>
          )}
        </LoadingWrapper>
      </main>
    </LayoutContext.Provider>
  )
}

export default Layout
